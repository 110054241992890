import {
  Box,
  Alert,
  AlertTitle,
  alpha,
  Collapse,
  Chip,
  Step,
  StepLabel,
  Stepper,
  StepIcon,
  IconButton,
  Tooltip,
  ToggleButton,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ClearIcon from '@mui/icons-material/Clear';
import BlockIcon from '@mui/icons-material/Block';
import AddIcon from '@mui/icons-material/Add';
import * as Extent from "ol/extent";
import { useDispatch } from 'react-redux';
import RouteIcon from '@mui/icons-material/Route';
import { toLonLat } from 'ol/proj';
import GeoMapWithTiles from '../../../features/geo-map/GeoMapWithTiles';
import style from './DeliveryGeoMapEditorWidget.module.css';
import Loading from '../../../shared/ui/Loading/Loading';
import LineVectorLayerV2 from '../../../shared/ui/GeoMap/LineVectorLayerV2';
import MarkerVectorLayerV2 from '../../../shared/ui/GeoMap/MarkerVectorLayerV2';
import { useAppSelector } from "../../../app/store";
import reducerPath from '../../../app/reducerPath';
import {
  LogisticTasksEnum, logisticTasksResourceActions,
  transformData
} from '../../../entities/logistic/logisticTasks/redux/logisticTasks.slice';
import { mapNames } from '../../../entities/map/fullScreenMap/redux/fullScreenMap.slice';
import { DEFAULT_EXTENT } from '../../../features/geo-map/GeoMap.constants';
import { getCoordinatesToLonLat } from '../../../shared/utils/coordinatesFormatting';
// eslint-disable-next-line boundaries/element-types

// Иконка для выбранного маркера
const createLocationSrcIcon = (color) => {
  const svgString = `
  <svg width="42" height="42" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2C7.8 2 4 5.22 4 10.2C4 13.38 6.45 17.12 11.34 21.43C11.72 21.76 12.29 21.76 12.67 21.43C17.55 17.12 20 13.38 20 10.2C20 5.22 16.2 2 12 2Z" fill="${color}" stroke="white"/>
  </svg>
  `;
  const svgBlob = new Blob([svgString], { type: "image/svg+xml" });
  return URL.createObjectURL(svgBlob);
};

// Иконка для стартового/финишного маркера
const createStartEndSrcIcon = (color) => {
  const svgString = `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="${color}" rx="4" stroke="white" />
  </svg>
  `;
  const svgBlob = new Blob([svgString], { type: "image/svg+xml" });
  return URL.createObjectURL(svgBlob);
};

// Маркер карты (выбранный/невыбранный)
function Marker(props) {
  const {
    selected, orderNumber, count, color, startIcon, endIcon, ...other
  } = props;
  const isSelected = selected?.includes(orderNumber);
  const src = useMemo(
    () => (
      isSelected
        ? createLocationSrcIcon(color)
        : (startIcon || endIcon) ? createStartEndSrcIcon(color) : null
    ),
    [color, isSelected, startIcon, endIcon]
  );
  return (
    <MarkerVectorLayerV2
      zIndex={isSelected ? 12 : 11}
      src={src}
      color={
      !selected?.length || isSelected || count < 5
        ? color
        : alpha(color, 0.6)
      }
      isChangeable={isSelected}
      selected={isSelected}
      {...other}
    />
  );
}

Marker.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  orderNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  count: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  startIcon: PropTypes.bool,
  endIcon: PropTypes.bool,
};

Marker.defaultProps = {
  startIcon: false,
  endIcon: false,
};

const mapName = mapNames.logisticDelivery;

// Отображает карту для задачи логистики по доставке заказов
function DeliveryGeoMapEditorWidget(props) {
  const {
    logisticTask: logisticTaskProps
  } = props;
  const { isLoading, isError } = logisticTaskProps;

  const isFullscreen = useAppSelector((state) => state[`${reducerPath.fullScreenMap}`])[mapName];
  const logisticTask = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);

  const showTrackModeTrigger = useMemo(() => logisticTask?.data?.status === 'SOLVED');

  const selectedOrdersIds = useMemo(() => (
    logisticTask?.selectedOrders
  ), [logisticTask?.selectedOrders]);

  const selectedVehicles = useMemo(() => (
    logisticTask?.selectedVehicles
  ), [logisticTask?.selectedVehicles]);

  const points = useMemo(() => {
    const newPoints = logisticTask?.data?.sourceWithSolution?.points;
    if (!newPoints) {
      return null;
    }
    const keys = Object.keys(newPoints);
    return {
      keys,
      values: newPoints,
    };
  }, [logisticTask?.data?.sourceWithSolution?.points]);

  const lines = useMemo(() => (
    logisticTask?.data?.sourceWithSolution?.lines
  ), [logisticTask?.data?.sourceWithSolution?.lines]);

  const tracks = useMemo(() => (
    logisticTask?.data?.sourceWithSolution?.tracks
  ), [logisticTask?.data?.sourceWithSolution?.tracks]);
  const [extent, setExtent] = useState([]);

  const [trackMode, setTrackMode] = useState(false);

  const switchMode = () => {
    setTrackMode((oldValue) => !oldValue);
  };

  const combinedPointsArray = useMemo(() => {
    let combinedArray = null;
    if (logisticTask?.data?.sourceWithSolution?.points) {
      const keys = Object.keys(logisticTask?.data?.sourceWithSolution?.points);
      const initialPoints = logisticTask?.data?.sourceWithSolution?.points;
      combinedArray = keys
        ?.reduce((prev, key) => {
          const point = initialPoints[key];
          if (point.coordinates) {
            prev.push(point.coordinates);
          }
          return prev;
        }, []);
    }
    return combinedArray;
  }, [logisticTask?.data?.sourceWithSolution?.points]);

  useEffect(() => {
    const savedExtent = window.localStorage.getItem('defaultExtent');
    let localExtent;
    if (!combinedPointsArray?.length) {
      localExtent = JSON.parse(savedExtent) || Extent.boundingExtent(DEFAULT_EXTENT);
    } else {
      localExtent = Extent.boundingExtent(combinedPointsArray);
    }
    setExtent(localExtent);
  }, [combinedPointsArray]);

  const saveExtent = useCallback((e) => {
    if (e?.map) {
      const currentExtent = e.map.getView().calculateExtent();
      window.localStorage.setItem('defaultExtent', JSON.stringify(currentExtent));
    }
  }, []);

  const saveZoom = useCallback((map) => {
    saveExtent({ map });
  }, []);

  const [drawingGeometryId, setDrawingGeometryId] = useState(null);

  const onClickDraw = (id) => {
    setDrawingGeometryId(id);
  };

  const dispatch = useDispatch();

  const updateCoords = (coords, id) => {
    const point = points.values[id];
    const newPoints = {
      ...points.values,
      [id]: {
        ...point,
        coordinates: coords,
        isEdited: true,
      }
    };
    const newData = JSON.parse(JSON.stringify(logisticTask?.data));
    const newEditedCoordinates = JSON.parse(JSON.stringify(logisticTask?.editedCoordinates));
    const srcOrder = newData.source.orderSourceList.find((o) => (
      o.id === point.orderNumber
    ));
    let solutionRouteOrder;
    newData.solution.routes.find((r) => {
      const res = r.orders.find((o) => o.orderSource.id === point.orderNumber);
      if (res) {
        solutionRouteOrder = res;
      }
      return res;
    });
    const solutionUnallocatedOrder = newData.solution.unallocatedOrders.find((o) => (
      o.orderSource.id === point.orderNumber
    ));
    const solutionOrder = solutionRouteOrder || solutionUnallocatedOrder;

    if (solutionOrder) {
      const lonLat = coords ? toLonLat(coords) : [null, null];
      if (point.isLoading) {
        srcOrder.loadingLat = lonLat[1] || null;
        srcOrder.loadingLon = lonLat[0] || null;
        solutionOrder.orderSource = solutionOrder?.orderSource && {
          ...solutionOrder.orderSource,
          loadingLat: lonLat[1] || null,
          loadingLon: lonLat[0] || null,
        };
        if (newEditedCoordinates?.[srcOrder.id]?.addressLoading) {
          newEditedCoordinates[srcOrder.id].addressLoading = null;
        }
      }
      if (point.isUnloading) {
        srcOrder.deliveryLat = lonLat[1] || null;
        srcOrder.deliveryLon = lonLat[0] || null;
        solutionOrder.orderSource = solutionOrder?.orderSource && {
          ...solutionOrder.orderSource,
          deliveryLat: lonLat[1] || null,
          deliveryLon: lonLat[0] || null,
        };
        if (newEditedCoordinates?.[srcOrder.id]?.addressUnloading) {
          newEditedCoordinates[srcOrder.id].addressUnloading = null;
        }
      }
    }

    newData.sourceWithSolution = {
      ...transformData(newData).sourceWithSolution,
      points: newPoints,
    };

    dispatch(logisticTasksResourceActions.setData({
      [LogisticTasksEnum.data]: newData,
      [LogisticTasksEnum.editedCoordinates]: newEditedCoordinates,
    }));
  };

  const onClickDelete = (curPoint) => {
    updateCoords(null, curPoint.id, curPoint);
  };

  const onStopDraw = (id, coords, curPoint) => {
    setDrawingGeometryId(null);
    updateCoords(coords, id, curPoint);
  };

  const onStopModify = (id, coords, curPoint) => {
    updateCoords(coords, id, curPoint);
  };

  useEffect(() => {
    if (isFullscreen) {
      window.scrollTo(0, 0);
    }
  }, [isFullscreen]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Ошибка</AlertTitle>
        Ошибка при загрузке данных
      </Alert>
    );
  }

  return (
    <Box className={isFullscreen ? style.formFull : style.form}>
      {showTrackModeTrigger && (
        <Tooltip
          title="Показывать треки"
          placement="top"
          sx={{
            alignSelf: "flex-end",
            position: isFullscreen
              ? { xs: "static", sm: "absolute" }
              : "static",
          }}
        >
          <ToggleButton
            disableRipple
            size="small"
            selected={trackMode}
            value=""
            onChange={switchMode}
            className={isFullscreen ? style.trackToggler : null}
          >
            <RouteIcon color="primary" />
          </ToggleButton>
        </Tooltip>
      )}
      <GeoMapWithTiles
        styles={isFullscreen ? style.geoMapFull : style.geoMap}
        extent={extent}
        isAnimateOnExtent
        name={mapName}
        isOffButtonFollowMode
        moveMapHandler={saveExtent}
        zoomHandler={saveZoom}
      >
        {(trackMode ? tracks : lines)?.map((line) => {
          const isSelected = selectedVehicles
            .includes(line?.r?.vehicleDetails?.registrationNumber);
          if (selectedVehicles?.length && !isSelected) {
            return null;
          }
          return (
            <LineVectorLayerV2
              key={line.id}
              zIndex={3}
              width={2}
            // lineDash={selectedOrdersIds?.length ? lineDash : undefined}
              color={selectedOrdersIds?.length && points?.keys?.length >= 5
                ? alpha(line.color, 0.4)
                : line.color}
            // color={route.color}
              id={line.id}
              initialCoords={line.coordinates}
            />
          );
        })}
        {points?.keys?.map((key) => {
          const point = points.values[key];
          const isSelected = selectedVehicles
            .includes(point?.r?.vehicleDetails?.registrationNumber);
          if (selectedVehicles?.length && !isSelected) {
            return null;
          }
          return (
            <Marker
              key={point.id}
              tooltipText={point.tooltipText}
              isStartDraw={drawingGeometryId === point.id}
              onStopDraw={(id, coords) => onStopDraw(id, coords, point)}
              onStopModify={(id, coords) => onStopModify(id, coords, point)}
              text={`${point.index}`}
              initialCoords={point.coordinates}
              id={point.id}
              selected={selectedOrdersIds}
              orderNumber={point.orderNumber}
              // count={vehicles.length}
              color={point.color}
              startIcon={point.startIcon}
              endIcon={point.endIcon}
            />
          );
        })}
      </GeoMapWithTiles>
      <Collapse in={!!selectedOrdersIds?.length}>
        <Stepper orientation="vertical">
          {points?.keys
            ?.map((key) => {
              const geometry = points.values[key];
              const isSelected = selectedOrdersIds?.includes(geometry.orderNumber);
              if (!isSelected) {
                return null;
              }
              const { loadingAddress, deliveryAddress } = geometry?.addresses || null;
              const coordinates = !!geometry.coordinates
                && getCoordinatesToLonLat(geometry.coordinates);
              return (
                <Step key={geometry.id} active>
                  <StepLabel
                    icon={
                      geometry.coordinates
                        ? <StepIcon icon={`${geometry.index}`} sx={{ color: geometry.color }} />
                        : <Tooltip title="Координаты не указаны"><WarningAmberIcon color="warning" /></Tooltip>
                    }
                    sx={{ alignItems: 'flex-start' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box sx={{
                        flexGrow: 1, flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between'
                      }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '3px' }}>
                            {geometry.label}
                            {geometry.isLoading && <SouthIcon sx={{ fontSize: '0.9em' }} />}
                            {geometry.isUnloading && <NorthIcon sx={{ fontSize: '0.9em' }} />}
                          </Box>
                          {geometry.isLoading && loadingAddress ? (
                            <Box sx={{ fontWeight: 'normal', fontSize: '0.85em', color: 'text.secondary' }}>
                              {loadingAddress}
                            </Box>
                          ) : geometry.isUnloading && deliveryAddress ? (
                            <Box sx={{ fontWeight: 'normal', fontSize: '0.85em', color: 'text.secondary' }}>
                              {deliveryAddress}
                            </Box>
                          ) : null}
                          {!!coordinates && (
                            <Box sx={{
                              fontWeight: 'normal', fontSize: '0.8rem', whiteSpace: 'nowrap', color: 'text.secondary'
                            }}
                            >
                              {coordinates}
                            </Box>
                          )}
                        </Box>
                        <Chip
                          label={`#${geometry.orderNumber}`}
                          size="small"
                          // color="success"
                          sx={{
                            fontWeight: 'normal',
                            marginTop: '3px',
                            // backgroundColor: geometry.color
                          }}
                        />
                      </Box>
                      {geometry.coordinates
                        ? (
                          <Tooltip title="Стереть">
                            <span>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => onClickDelete(geometry)}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )
                        : drawingGeometryId === geometry.id
                          ? (
                            <Tooltip title="Отмена">
                              <span>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => onClickDraw(null)}
                                >
                                  <BlockIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )
                          : (
                            <Tooltip title="Указать на карте">
                              <span>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => onClickDraw(geometry.id)}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                    </Box>
                  </StepLabel>
                </Step>
              );
            })}
        </Stepper>
      </Collapse>
    </Box>
  );
}

DeliveryGeoMapEditorWidget.propTypes = {
  logisticTask: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }).isRequired,
};

export default DeliveryGeoMapEditorWidget;
