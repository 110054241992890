// eslint-disable-next-line boundaries/no-unknown-files
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { useDispatch, useSelector } from "react-redux";
import reducerPath from "./reducerPath";
import { vehicleFuelEfficiencyApi } from "../entities/vehicles/vehicleFuelEfficiency/redux/vehicleFuelEfficiency.api";
import { vehicleFuelEfficiencyReducer } from "../entities/vehicles/vehicleFuelEfficiency/redux/vehicleFuelEfficiency.slice";

import { adaptersResourceApi } from "../entities/equipments/adaptersResource/redux/adaptersResource.api";
import { adaptersResourceReducer } from "../entities/equipments/adaptersResource/redux/adaptersResource.slice";

import { sensorResourceTypeApi } from "../entities/equipments/sensorResourceType/redux/sensorResourceType.api";
import { sensorResourceTypeReducer } from "../entities/equipments/sensorResourceType/redux/sensorResourceType.slice";

import { adapterResourceTypeApi } from "../entities/equipments/adapterResourceType/redux/adapterResourceType.api";
import { adapterResourceTypeReducer } from "../entities/equipments/adapterResourceType/redux/adapterResourceType.slice";

import { sensorsResourceApi } from "../entities/equipments/sensorsResource/redux/sensorsResource.api";
import { sensorsResourceReducer } from "../entities/equipments/sensorsResource/redux/sensorsResource.slice";

import { sensorsSourcesResourceApi } from "../entities/equipments/sensorsSourcesResource/redux/sensorsSourcesResource.api";
import {
  sensorsSourcesResourceReducer
} from "../entities/equipments/sensorsSourcesResource/redux/sensorsSourcesResource.slice";

import { platoonGroupsResourceApi } from "../entities/platoons/platoonGroupsResource/redux/platoonGroupsResource.api";
import {
  platoonGroupsResourceReducer
} from "../entities/platoons/platoonGroupsResource/redux/platoonGroupsResource.slice";

import {
  platoonRequestsResourceApi
} from "../entities/platoons/platoonRequestsResource/redux/platoonRequestsResource.api";
import {
  platoonRequestsResourceReducer
} from "../entities/platoons/platoonRequestsResource/redux/platoonRequestsResource.slice";

import { vehicleApi } from "../entities/vehicles/vehicle/redux/vehicle.api";
import { vehicleReducer } from "../entities/vehicles/vehicle/redux/vehicle.slice";

import { vehicleRoutesReducer } from "../entities/vehicles/VehiclesRoute/vehiclesRoutes/redux/vehiclesRoutes.slice";
import { vehiclesRoutesApi } from "../entities/vehicles/VehiclesRoute/vehiclesRoutes/redux/vehiclesRoutes.api";

import {
  platoonGroupVehiclesResourceApi
} from "../entities/platoons/platoonGroupVehiclesResource/redux/platoonGroupVehiclesResource.api";
import {
  platoonGroupVehiclesResourceReducer
} from "../entities/platoons/platoonGroupVehiclesResource/redux/platoonGroupVehiclesResource.slice";
import {
  platoonGroupActivitiesResourceApi
} from "../entities/platoons/platoonGroupActivitiesResource/redux/platoonGroupActivitiesResource.api";
import {
  platoonGroupActivitiesResourceReducer
} from "../entities/platoons/platoonGroupActivitiesResource/redux/platoonGroupActivitiesResource.slice";
import {
  platoonGroupParamsResourceApi
} from "../entities/platoons/platoonGroupParamsResource/redux/platoonGroupParamsResource.api";
import {
  platoonGroupParamsResourceReducer
} from "../entities/platoons/platoonGroupParamsResource/redux/platoonGroupParamsResource.slice";
import {
  vehiclesRoutePointsResourceApi
} from "../entities/vehicles/VehiclesRoute/vehiclesRoutePointsResource/redux/vehiclesRoutePointsResource.api";
import {
  vehicleRoutePointsResource
} from "../entities/vehicles/VehiclesRoute/vehiclesRoutePointsResource/redux/vehiclesRoutePointsResource.slice";
import { alertsResourceReducer } from "../entities/alerts/alertsResource/redux/alertsResource.slice";
import { alertsResourceApi } from "../entities/alerts/alertsResource/redux/alertsResource.api";
import { trackPointsResourceApi } from "../entities/vehicles/trackPointsResource/redux/trackPointsResource.api";
import { trackPointsResourceReducer } from "../entities/vehicles/trackPointsResource/redux/trackPointsResource.slice";
import {
  appUserSettingsResourceReducer
} from "../entities/account/appUserSettingsResource/redux/appUserSettingsResource.slice";
import {
  appUserSettingsResourceApi
} from "../entities/account/appUserSettingsResource/redux/appUserSettingsResource.api";
import { appUsersResourceApi } from "../entities/account/appUsersResource/redux/appUsersResource.api";
import { appUsersResourceReducer } from "../entities/account/appUsersResource/redux/appUsersResource.slice";
import { accountResourceApi } from "../entities/account/accountResource/redux/accountResource.api";
import { accountResourceReducer } from "../entities/account/accountResource/redux/accountResource.slice";
import { usersResourceApi } from "../entities/account/usersResource/redux/usersResource.api";
import { usersResourceReducer } from "../entities/account/usersResource/redux/usersResource.slice";
import { alertsSettingsResourceApi } from "../entities/alerts/alertsSettingsResource/redux/alertsSettingsResource.api";
import {
  alertsSettingsResourceReducer
} from "../entities/alerts/alertsSettingsResource/redux/alertsSettingsResource.slice";
import { tariffPlansResourceReducer } from "../entities/finances/tariffPlansResource/redux/tariffPlansResource.slice";
import { tariffPlansResourceApi } from "../entities/finances/tariffPlansResource/redux/tariffPlansResource.api";

import {
  alertsChannelsResourceReducer,
} from "../entities/alerts/alertsChannelsResource/redux/alertsChannelsResource.slice";
import { alertsChannelsResourceApi } from "../entities/alerts/alertsChannelsResource/redux/alertsChannelsResource.api";
import { alertsTypesResourceReducer } from "../entities/alerts/alertsTypesResource/redux/alertsTypesResource.slice";
import { alertsTypesResourceApi } from "../entities/alerts/alertsTypesResource/redux/alertsTypesResource.api";
import { divisionsResourceApi } from "../entities/divisions/divisionsResource/redux/divisionsResource.api";
import { divisionsResourceReducer } from "../entities/divisions/divisionsResource/redux/divisionsResource.slice";
import {
  vehicleMonthMileagesResourceApi
} from "../entities/vehicles/vehicleMonthMileagesResource/redux/vehicleMonthMileagesResource.api";
import {
  vehicleWeekMileagesResourceApi
} from "../entities/vehicles/vehicleWeekMileagesResource/redux/vehicleWeekMileagesResource.api";
import apiSlice from "../shared/api/apiSlice";
import { companyTabsPanelStateReducer } from "../entities/companies/companyTabsPanels/redux/companyTabsPanels.slice";
import { paymentResourceApi } from "../entities/payments/paymentResource/redux/paymentResource.api";
import { companyAccountResourceApi } from "../entities/account/companyAccountResource/redux/companyAccountResource.api";
import { followModeReducer } from "../entities/map/followMode/redux/followMode.slice";
import { zoomControllerReducer } from "../entities/map/zoomController/redux/zoomController.slice";
import { vkResourceApi } from "../entities/vk/vkResource/redux/vkResource.api";
// eslint-disable-next-line import/no-unresolved
import { authReducer } from "../entities/auth/tokenResource/redux/tokenResource";
import { geocodeApi, logisticTasksApi } from "../entities/logistic/logisticTasks/redux/logisticTasks.api";
import { companyCargosApi } from "../entities/companies/companyCargos/redux/companyCargos.api";
import { logisticTasksResourceReducer } from "../entities/logistic/logisticTasks/redux/logisticTasks.slice";
import { logisticTasksBalanceResourceReducer } from "../entities/logistic/logisticTasks/redux/logisticTasksBalance.slice";
import { fullScreenMapSlice } from "../entities/map/fullScreenMap/redux/fullScreenMap.slice";
import { reportsResourceApi } from "../entities/reports/reportsResource/redux/reportsResource.api";
import { reportsResourceReducer } from "../entities/reports/reportsResource/redux/reportsResource.slice";

const store = configureStore({
  reducer: {
    [`${reducerPath.vehicleFuelEfficiency}/counter`]: vehicleFuelEfficiencyReducer,
    [vehicleFuelEfficiencyApi.reducerPath]: vehicleFuelEfficiencyApi.reducer,

    [`${reducerPath.adaptersResource}/counter`]: adaptersResourceReducer,
    [adaptersResourceApi.reducerPath]: adaptersResourceApi.reducer,

    [`${reducerPath.adapterResourceType}/counter`]: adapterResourceTypeReducer,
    [adapterResourceTypeApi.reducerPath]: adapterResourceTypeApi.reducer,

    [`${reducerPath.sensorResourceType}/counter`]: sensorResourceTypeReducer,
    [sensorResourceTypeApi.reducerPath]: sensorResourceTypeApi.reducer,

    [`${reducerPath.sensorResource}/counter`]: sensorsResourceReducer,
    [sensorsResourceApi.reducerPath]: sensorsResourceApi.reducer,

    [`${reducerPath.sensorSourceResource}/counter`]: sensorsSourcesResourceReducer,
    [sensorsSourcesResourceApi.reducerPath]: sensorsSourcesResourceApi.reducer,

    [`${reducerPath.platoonGroupsResource}/counter`]: platoonGroupsResourceReducer,
    [platoonGroupsResourceApi.reducerPath]: platoonGroupsResourceApi.reducer,

    [`${reducerPath.platoonRequestsResource}/counter`]: platoonRequestsResourceReducer,
    [platoonRequestsResourceApi.reducerPath]: platoonRequestsResourceApi.reducer,

    [`${reducerPath.vehicle}/counter`]: vehicleReducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,

    [`${reducerPath.vehicleRoutes}/counter`]: vehicleRoutesReducer,
    [vehiclesRoutesApi.reducerPath]: vehiclesRoutesApi.reducer,

    [`${reducerPath.platoonGroupVehiclesResource}/counter`]: platoonGroupVehiclesResourceReducer,
    [platoonGroupVehiclesResourceApi.reducerPath]: platoonGroupVehiclesResourceApi.reducer,

    [`${reducerPath.platoonGroupActivitiesResource}/counter`]: platoonGroupActivitiesResourceReducer,
    [platoonGroupActivitiesResourceApi.reducerPath]: platoonGroupActivitiesResourceApi.reducer,

    [`${reducerPath.platoonGroupParamsResource}/counter`]: platoonGroupParamsResourceReducer,
    [platoonGroupParamsResourceApi.reducerPath]: platoonGroupParamsResourceApi.reducer,

    [`${reducerPath.vehicleRoutePointsResource}/counter`]: vehicleRoutePointsResource,
    [vehiclesRoutePointsResourceApi.reducerPath]: vehiclesRoutePointsResourceApi.reducer,

    [`${reducerPath.alertsResource}/counter`]: alertsResourceReducer,
    [alertsResourceApi.reducerPath]: alertsResourceApi.reducer,

    [`${reducerPath.trackPointsResource}/counter`]: trackPointsResourceReducer,
    [trackPointsResourceApi.reducerPath]: trackPointsResourceApi.reducer,

    [`${reducerPath.appUsersSettingsResource}/counter`]: appUserSettingsResourceReducer,
    [appUserSettingsResourceApi.reducerPath]: appUserSettingsResourceApi.reducer,

    [`${reducerPath.appUsersResource}/counter`]: appUsersResourceReducer,
    [appUsersResourceApi.reducerPath]: appUsersResourceApi.reducer,

    [`${reducerPath.accountResource}/counter`]: accountResourceReducer,
    [accountResourceApi.reducerPath]: accountResourceApi.reducer,

    [`${reducerPath.usersResource}/counter`]: usersResourceReducer,
    [usersResourceApi.reducerPath]: usersResourceApi.reducer,

    [`${reducerPath.alertsSettingsResource}/counter`]: alertsSettingsResourceReducer,
    [alertsSettingsResourceApi.reducerPath]: alertsSettingsResourceApi.reducer,

    [`${reducerPath.tariffPlansResource}/counter`]: tariffPlansResourceReducer,
    [tariffPlansResourceApi.reducerPath]: tariffPlansResourceApi.reducer,

    [`${reducerPath.alertsChannelsResource}/counter`]: alertsChannelsResourceReducer,
    [alertsChannelsResourceApi.reducerPath]: alertsChannelsResourceApi.reducer,

    [`${reducerPath.alertsTypesResource}/counter`]: alertsTypesResourceReducer,
    [alertsTypesResourceApi.reducerPath]: alertsTypesResourceApi.reducer,

    [`${reducerPath.divisionsResource}/counter`]: divisionsResourceReducer,
    [divisionsResourceApi.reducerPath]: divisionsResourceApi.reducer,

    [`${reducerPath.companyTabsPanelState}/counter`]: companyTabsPanelStateReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,

    [`${reducerPath.logisticTasksResource}/counter`]: logisticTasksResourceReducer,

    [`${reducerPath.logisticTasksBalanceResource}/counter`]: logisticTasksBalanceResourceReducer,

    [vehicleMonthMileagesResourceApi.reducerPath]: vehicleMonthMileagesResourceApi.reducer,

    [vehicleWeekMileagesResourceApi.reducerPath]: vehicleWeekMileagesResourceApi.reducer,

    [paymentResourceApi.reducerPath]: paymentResourceApi.reducer,

    [companyAccountResourceApi.reducerPath]: companyAccountResourceApi.reducer,

    [`${reducerPath.followMode}/counter`]: followModeReducer,

    [`${reducerPath.zoomController}/counter`]: zoomControllerReducer,

    [`${reducerPath.fullScreenMap}`]: fullScreenMapSlice.reducer,

    [`${reducerPath.tokenResource}/counter`]: authReducer,

    [vkResourceApi.reducerPath]: vkResourceApi.reducer,

    [logisticTasksApi.reducerPath]: logisticTasksApi.reducer,
    [geocodeApi.reducerPath]: geocodeApi.reducer,

    [companyCargosApi.reducerPath]: companyCargosApi.reducer,

    [`${reducerPath.reportsResource}`]: reportsResourceReducer,
    [reportsResourceApi.reducerPath]: reportsResourceApi.reducer,

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(
      vehicleFuelEfficiencyApi.middleware,
      adaptersResourceApi.middleware,
      sensorResourceTypeApi.middleware,
      adapterResourceTypeApi.middleware,
      sensorsResourceApi.middleware,
      sensorsSourcesResourceApi.middleware,
      platoonGroupsResourceApi.middleware,
      platoonRequestsResourceApi.middleware,
      vehicleApi.middleware,
      vehiclesRoutesApi.middleware,
      platoonGroupVehiclesResourceApi.middleware,
      platoonGroupActivitiesResourceApi.middleware,
      platoonGroupParamsResourceApi.middleware,
      vehiclesRoutePointsResourceApi.middleware,
      alertsResourceApi.middleware,
      trackPointsResourceApi.middleware,
      appUserSettingsResourceApi.middleware,
      appUsersResourceApi.middleware,
      accountResourceApi.middleware,
      usersResourceApi.middleware,
      alertsSettingsResourceApi.middleware,
      tariffPlansResourceApi.middleware,
      alertsChannelsResourceApi.middleware,
      alertsTypesResourceApi.middleware,
      divisionsResourceApi.middleware,
      apiSlice.middleware,
      vehicleMonthMileagesResourceApi.middleware,
      vehicleWeekMileagesResourceApi.middleware,
      paymentResourceApi.middleware,
      companyAccountResourceApi.middleware,
      vkResourceApi.middleware,
      logisticTasksApi.middleware,
      geocodeApi.middleware,
      companyCargosApi.middleware,
      reportsResourceApi.middleware,
    )
});

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();

export default store;

setupListeners(store.dispatch);
