import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Connect } from '@vkontakte/superappkit';
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import style from './Header.module.css';
// eslint-disable-next-line boundaries/element-types
import { clearStorage } from '../../../entities/auth/tokenResource/redux/tokenResource';
import { LANGUAGES } from '../../../i18n';
import useAuth from '../../lib/hooks/useAuth';

const appVersion = process.env.REACT_APP_VERSION || '';

function Header(props) {
  const { handleDrawer } = props;
  const { user, companyAccount, isCompanyAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [adminLogin, setAdminLogin] = useState();

  useEffect(() => {
    const atoken = window.sessionStorage.getItem('atoken');
    const aLogin = window.sessionStorage.getItem('admin_login');
    if (atoken) {
      setAdminLogin(aLogin || 'admin');
    } else {
      setAdminLogin(undefined);
    }
  }, []);
  const { t, i18n } = useTranslation('translation');

  const { currentLang, nextLang } = useMemo(() => {
    const lngs = Object.keys(LANGUAGES);
    // В массив i18n.languages попадают только значения из доступных
    // В i18n.language может быть непредвиденное значение языка браузера с суффиксами
    const currentLng = i18n?.languages ? i18n?.languages[0] : i18n?.language;
    const currentLngIndex = lngs.findIndex((l) => currentLng === l);
    let nextLngIndex = currentLngIndex + 1;
    nextLngIndex = nextLngIndex > lngs.length - 1 ? 0 : nextLngIndex;
    return { currentLang: lngs[currentLngIndex], nextLang: lngs[nextLngIndex] };
  }, [i18n?.language]);

  const toggleLanguage = useCallback(() => {
    if (i18n?.language) {
      i18n.changeLanguage(nextLang);
    }
  }, [i18n?.language]);

  const balanceStr = useMemo(() => {
    if (companyAccount?.balance !== undefined) {
      // TODO Использовать интернационализацию здесь:
      return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
        companyAccount.balance,
      );
    }
    return '';
  }, [companyAccount?.balance]);

  const logout = () => {
    Connect.logout().finally(() => {
      clearStorage();
      // eslint-disable-next-line no-undef
      window.location.href = '/';
    });
  };

  const goToProfile = () => {
    navigate('account/info');
  };

  const resetAdminMode = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const atoken = window.sessionStorage.getItem('atoken');
    clearStorage();
    window.sessionStorage.setItem('token', atoken);
    navigate('/impersonize', { state: { from: location } });
  };

  return (
    <AppBar
      sx={{ zIndex: 1500, position: 'relative' }}
      position="static"
      color="primary"
      className={style.header}
    >
      <Toolbar sx={{ gap: { xs: 1, sm: 2 } }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Box
          className={style.headerResponsive}
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            marginLeft: { xs: '-8px', sm: 0 },
          }}
        >
          <Box className={style.headerMain}>
            <Box className={style.headerText}>
              <Typography
                variant="h6"
                component="div"
                className={style.titleText}
                sx={{ fontSize: { xs: '0.95rem', sm: '1.25rem' } }}
              >
                {t('title')}
              </Typography>
              <Typography
                sx={{ display: { xs: 'none', sm: 'block' } }}
                variant="caption"
                component="div"
              >
                {appVersion}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{ display: { sm: 'flex', xs: 'none' } }}
            mr={1}
            className={style.buttons}
          >
            <Tooltip title={t('switchLanguage', { lng: nextLang })}>
              <Button onClick={toggleLanguage} color="inherit">
                {LANGUAGES[currentLang].short}
              </Button>
            </Tooltip>
            <Tooltip title="Быстрый старт">
              <IconButton color="inherit" href="/quick-start">
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          {isCompanyAdmin && (
            <Typography
              sx={{
                backgroundColor: (theme) => (companyAccount?.balance < 0
                  ? theme.palette.error.main
                  : 'inherit'),
                padding: () => (companyAccount?.balance < 0 ? '0 4px' : 0),
                borderRadius: '4px',
                fontSize: { xs: '0.8rem', sm: '1rem' },
              }}
            >
              {balanceStr}
            </Typography>
          )}
        </Box>
        <div className={style.buttons}>
          <Button
            onClick={goToProfile}
            color="inherit"
            startIcon={<PersonIcon m={0} />}
            className={style.userBtn}
            sx={{
              gap: { xs: '4px', sm: '8px' },
            }}
          >
            <Box className={style.userBtnTxt} sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
              <Box className={style.userName}>
                {user.login}
              </Box>
              {adminLogin && (
                <Typography
                  onClick={resetAdminMode}
                  variant="inherit"
                  component="span"
                  className={style.userName}
                >
                  (
                  {adminLogin}
                  )
                </Typography>
              )}
            </Box>
          </Button>
          <Button onClick={logout} color="inherit" sx={{ display: { xs: 'none', sm: 'block' } }}>
            Выход
          </Button>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={logout}
            sx={{ display: { xs: 'flex', sm: 'none' } }}
          >
            <LogoutIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
};

export default Header;
