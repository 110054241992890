import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PropTypes from 'prop-types';
import { toLonLat } from 'ol/proj';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import DeliveryGeoMapEditorWidget from '../../../widgets/logistics/DeliveryGeoMapEditorWidget/DeliveryGeoMapEditorWidget';
import LogisticTaskBll from '../../../entities/logistic/logisticTasks/LogisticTaskBll/LogisticTaskBll';
import DeliveryMainWidget from "../../../widgets/logistics/DeliveryMainWidget/DeliveryMainWidget";
import DeliveryMainWidgetSecond from "../../../widgets/logistics/DeliveryMainWidget/DeliveryMainWidgetSecond";
import { useAppSelector } from '../../../app/store';
import reducerPath from '../../../app/reducerPath';
import { mapNames } from '../../../entities/map/fullScreenMap/redux/fullScreenMap.slice';
import useShowError from '../../../shared/lib/hooks/useShowError';
import Loading from '../../../shared/ui/Loading/Loading';
import { useCreateVehicleRoutesMutation, useLazyFindAddressQuery, useReorderLogisticTaskMutation } from '../../../entities/logistic/logisticTasks/redux/logisticTasks.api';
import {
  CARGO_PLACEMENT_CRITERIA,
  LogisticTasksEnum,
  OPTIMIZATION_CRITERIA,
  ROUTE_NAME_TPLS,
  SPEED_PROFILES,
  logisticTasksResourceActions,
  transformData,
} from '../../../entities/logistic/logisticTasks/redux/logisticTasks.slice';
import FormDialog from '../../../shared/ui/FormDialog/FormDialog';

const cancelEvent = (evt) => {
  evt.nativeEvent?.target?.blur?.();
  evt.nativeEvent?.preventDefault?.();
  evt.nativeEvent?.stopPropagation?.();
  evt.nativeEvent?.stopImmediatePropagation?.();
  evt.target?.blur?.();
  evt.preventDefault?.();
  evt.stopPropagation?.();
};

const parseCoords = (coordinates) => {
  if (!coordinates) {
    return null;
  }
  const lonLat = toLonLat(coordinates);
  return { lon: lonLat[0], lat: lonLat[1] };
};

function DeliveryMainPanelBll(props) {
  const {
    logisticTask,
    patchLogisticTask,
    solveLogisticTask,
    resultPatchLogisticTask,
    startPolling,
    stopPolling,
    taskStatus,
    stopLogisticTask,
  } = props;
  const {
    data,
    data: { name: taskName },
    routeNameTemplate,
    rePlanDate,
    editedCoordinates,
    selectedVehicles,
  } = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);
  const isFullscreen = useAppSelector((state) => state[`${reducerPath.fullScreenMap}`])[mapNames.logisticDelivery];
  const showError = useShowError();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stopTaskTrigger] = stopLogisticTask;

  const [isRunning, setIsRunning] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [waitForStatusFlag, setWaitForStatusFlag] = useState(false);

  const isLogisticTaskPristine = useAppSelector((state) => {
    const store = state[`${reducerPath.logisticTasksResource}/counter`];

    return store[LogisticTasksEnum.data] === store[LogisticTasksEnum.initialData]
      && (rePlanDate ? rePlanDate === logisticTask?.data?.source.routeStartDate
        : !logisticTask?.data?.source.routeStartDate);
  });

  const [
    reorderLogisticTask,
    reorderLogisticTaskResult,
  ] = useReorderLogisticTaskMutation();

  const [
    createVehicleRoutes,
    createVehicleRoutesResult,
  ] = useCreateVehicleRoutesMutation();

  const [
    findAddress,
    // findAddressResult,
  ] = useLazyFindAddressQuery();

  const findAddressHandler = (key, address, row) => findAddress(address).then((res) => {
    const resData = res.data?.[0];
    dispatch(logisticTasksResourceActions.setEditedAddresses({
      searchResult: resData,
      order: row,
      key
    }));
    return res;
  });

  useEffect(() => {
    dispatch(logisticTasksResourceActions.setRePlanDate((
      logisticTask?.data?.source.routeStartDate || null
    )));
  }, [logisticTask]);

  useEffect(() => {
    switch (taskStatus.status) {
      case 'RUNNING':
        setIsRunning(true);
        setIsStopping(false);
        startPolling();
        break;
      case 'STOPPING':
        setIsRunning(false);
        setIsStopping(true);
        startPolling();
        break;
      case 'SOLVED':
        setIsRunning(false);
        setIsStopping(false);
        stopPolling();
        logisticTask.refetch();
        break;
      default:
        setIsRunning(false);
        setIsStopping(false);
        stopPolling();
    }
    setWaitForStatusFlag(false);
  }, [taskStatus]);

  useEffect(() => {
    if (createVehicleRoutesResult.status === 'fulfilled') {
      navigate(`/companies/${logisticTask.data.companyId}/routes`);
    }
  }, [createVehicleRoutesResult.status]);

  useEffect(() => {
    if (reorderLogisticTaskResult.status === 'fulfilled') {
      const { data: payload } = reorderLogisticTaskResult;
      const dataWithMissingRoutes = {
        ...payload,
        solution: {
          ...logisticTask.data.solution,
          // eslint-disable-next-line react/prop-types
          routes: logisticTask.data.solution.routes.map((r) => {
            const newRoute = payload.solution.routes.find((route) => (
              route.vehicleDetails.registrationNumber === r.vehicleDetails.registrationNumber
            ));
            return newRoute || r;
          })
        },
      };
      const newData = transformData(dataWithMissingRoutes);
      dispatch(logisticTasksResourceActions.setData({ data: newData }));
    }
  }, [reorderLogisticTaskResult.status]);

  const [showReordering, setShowReordering] = React.useState(false);
  const [isOpenCreateVehicleRouteDialog, setIsOpenCreateVehicleRouteDialog] = React.useState(false);

  const onCLickSave = (status = null) => {
    const orders = [...JSON.parse(JSON.stringify(data.source.orderSourceList))];
    const points = JSON.parse(JSON.stringify(data.sourceWithSolution.points));
    const keys = Object.keys(points);

    // Перебираем измененные коодинаты точек заказов, сохраняем в источник
    const orderSourceList = keys.reduce((prev, key) => {
      const point = points[key];
      const newPrev = [...prev];
      const index = orders.findIndex((order) => order.id === point.orderNumber);
      if (point.isEdited) {
        const coords = parseCoords(point.coordinates);
        if (point.isLoading) {
          newPrev[index] = {
            ...newPrev[index],
            loadingLon: coords?.lon,
            loadingLat: coords?.lat,
          };
        }
        if (point.isUnloading) {
          newPrev[index] = {
            ...newPrev[index],
            deliveryLon: coords?.lon,
            deliveryLat: coords?.lat,
          };
        }
      }
      const foundCoord = editedCoordinates[point.orderNumber];
      if (foundCoord) {
        if (point.isLoading && foundCoord.addressLoading) {
          newPrev[index] = {
            ...newPrev[index],
            loadingLon: foundCoord.addressLoading.lon,
            loadingLat: foundCoord.addressLoading.lat,
            loadingAddress: foundCoord.addressLoading.sourceAddress,
            addresses: {
              ...newPrev[index].addresses,
              loadingAddress: foundCoord.addressLoading.sourceAddress,
            }
          };
        }
        if (point.isUnloading && foundCoord.addressUnloading) {
          newPrev[index] = {
            ...newPrev[index],
            deliveryLon: foundCoord.addressUnloading.lon,
            deliveryLat: foundCoord.addressUnloading.lat,
            deliveryAddress: foundCoord.addressUnloading.sourceAddress,
            addresses: {
              ...newPrev[index].addresses,
              deliveryAddress: foundCoord.addressUnloading.sourceAddress,
            },
          };
        }
      }
      return newPrev;
    }, orders);
    const body = {
      name: data?.name || "",
      logisticTaskId: logisticTask.data.logisticTaskId,
      source: {
        ...data.source,
        orderSourceList,
        routeStartDate: rePlanDate || null,
      },
      solution: data?.solution || [],
    };
    if (status) {
      body.status = status;
    }
    return patchLogisticTask({ body }).catch(() => showError('Ошибка при сохранении данных.'));
  };

  const onCLickOptimize = () => {
    setWaitForStatusFlag(true);
    onCLickSave('RUNNING')
      .catch(() => showError('Ошибка при оптимизации решения.'));
  };

  const onStopOptimizing = () => {
    setWaitForStatusFlag(true);
    stopTaskTrigger({ id: logisticTask.data.logisticTaskId })
      .catch(() => showError('Ошибка при остановке оптимизации.'));
  };

  const onCLickCreateRoutes = () => {
    setIsOpenCreateVehicleRouteDialog(true);
  };

  const processCreateRoutes = async () => {
    const { data: newData } = await onCLickSave();
    await createVehicleRoutes({
      body: newData,
      rePlanDate,
      routeNameTemplate,
      taskName,
      selectedVehicles,
    }).catch(() => showError('Ошибка при создании маршрутов.'));
  };

  const showAwaitForOptimisationToast = useCallback((evt) => {
    if (isRunning || isStopping) {
      toast.error('Дождитесь завершения оптимизации', {
        duration: 3000,
        style: { maxWidth: '100%' },
      });
      cancelEvent(evt);
    }
  }, [isRunning, isStopping]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={solveLogisticTask.isLoading || resultPatchLogisticTask.isLoading
          || reorderLogisticTaskResult.isLoading}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="body1">
            {solveLogisticTask.isLoading
              ? 'Оптимизация...'
              : 'Сохранение...'}
          </Typography>
        </Box>
      </Backdrop>
      <FormDialog
        disableTextField
        open={isOpenCreateVehicleRouteDialog}
        onSave={processCreateRoutes}
        handleClose={() => {
          setIsOpenCreateVehicleRouteDialog(false);
          dispatch(logisticTasksResourceActions.setRouteNameTemplate(ROUTE_NAME_TPLS[0].value));
        }}
        dialogContent={(
          <>
            {!!selectedVehicles.length && (
              <List dense>
                {selectedVehicles.map((item) => {
                  const color = data.sourceWithSolution.vehicleDetailsList.find((v) => (
                    v.registrationNumber === item
                  ))?.color;
                  return (
                    <ListItem disablePadding>
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        <Box sx={{ backgroundColor: color, width: '15px', height: '15px' }} />
                      </ListItemIcon>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            )}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="route-name-tpl">Шаблон имени маршрута</InputLabel>
              <Select
                id="route-name-tpl"
                labelId="route-name-tpl"
                value={routeNameTemplate}
                onChange={(evt) => (
                  dispatch(logisticTasksResourceActions.setRouteNameTemplate(evt.target.value))
                )}
                label="Шаблон имени маршрута"
              >
                {ROUTE_NAME_TPLS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        title="Создание маршрутов"
        saveTitle="Создать"
        cancelTitle="Отмена"
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isFullscreen ? 'column' : 'row',
            gap: '10px',
            opacity: (isRunning || isStopping) ? 0.6 : 1,
          }}
          onFocusCapture={(isRunning || isStopping) ? cancelEvent : null}
          onClickCapture={showAwaitForOptimisationToast}
        >
          <DeliveryGeoMapEditorWidget logisticTask={logisticTask} sx={{ pointerEvents: (isRunning || isStopping) ? 'none' : 'inherit' }} />
          {logisticTask?.isLoading
            ? (
              <Loading />
            )
            : (
              <Box sx={{ pointerEvents: (isRunning || isStopping) ? 'none' : 'inherit' }}>
                <DeliveryMainWidget />
                <DeliveryMainWidgetSecond
                  showReordering={showReordering}
                  setShowReordering={setShowReordering}
                  reorderLogisticTask={reorderLogisticTask}
                  findAddress={findAddressHandler}
                />
              </Box>
            )}
        </Box>
        <Box
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            display: 'flex',
            gap: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => onCLickSave()}
            disabled={
              !!logisticTask?.isLoading || isLogisticTaskPristine || waitForStatusFlag || isStopping
            }
          >
            Сохранить
          </Button>
          <Button
            variant="contained"
            onClick={isRunning ? onStopOptimizing : isStopping ? () => {} : onCLickOptimize}
            disabled={!!logisticTask?.isLoading || waitForStatusFlag || isStopping}
            startIcon={isRunning ? <CircularProgress size="20px" color="inherit" /> : null}
          >
            {isRunning ? 'Остановить оптимизацию' : isStopping ? 'Останавливается' : 'Оптимизировать'}
          </Button>
          <Button
            variant="contained"
            onClick={onCLickCreateRoutes}
            disabled={!!logisticTask?.isLoading || waitForStatusFlag || isStopping}
          >
            Создать маршруты
          </Button>
        </Box>
      </Box>
    </>
  );
}

// Отображает панель "Доставка"
function DeliveryMainPanel() {
  const params = useParams();
  const logisticTaskId = parseInt(params.logisticTaskId, 10);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary' }}>
        Построение маршрутов для перевозки заказов
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <LogisticTaskBll
          logisticTaskId={logisticTaskId}
        >
          <DeliveryMainPanelBll />
        </LogisticTaskBll>
      </Box>
    </Box>
  );
}

DeliveryMainPanelBll.propTypes = {
  logisticTask: PropTypes.shape({
    data: PropTypes.shape({
      startTime: PropTypes.string,
      logisticTaskId: PropTypes.number.isRequired,
      status: PropTypes.oneOf(['CREATED', 'RUNNING', 'SOLVED', 'PAUSE', 'STOPPED', 'STOPPING']),
      source: PropTypes.shape({
        vehicleType: PropTypes.oneOf(Object.values(SPEED_PROFILES)),
        optimizationCriteria: PropTypes.oneOf(Object.values(OPTIMIZATION_CRITERIA)),
        cargoPlacementCriteria: PropTypes.oneOf(Object.values(CARGO_PLACEMENT_CRITERIA)),
        vehicleDetailsList: PropTypes.arrayOf(
          PropTypes.shape({
            registrationNumber: PropTypes.string,
            liftingCapacity: PropTypes.number,
            maxWeightPercents: PropTypes.number,
            availableCargoCount: PropTypes.number,
            maxCargoCountPercents: PropTypes.number,
            spaceLength: PropTypes.number,
            spaceWidth: PropTypes.number,
            spaceHeight: PropTypes.number,
            maxVolumePercents: PropTypes.number,
            vehicleProperties: PropTypes.string,
            excludedProperties: PropTypes.string,
            start: PropTypes.string,
            startLat: PropTypes.number,
            startLon: PropTypes.number,
            finishLat: PropTypes.number,
            finishLon: PropTypes.number,
            firstShiftWorkTimeFrom: PropTypes.string,
            firstShiftWorkTimeTo: PropTypes.string,
            isFirstShiftHardTime: PropTypes.bool,
            maxCargoCount: PropTypes.number,
            firstShiftHardTime: PropTypes.bool,
          })
        ),
        orderSourceList: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            additionalNum: PropTypes.string,
            deliveryLat: PropTypes.number,
            deliveryLon: PropTypes.number,
            clientName: PropTypes.string,
            deliveryAddress: PropTypes.string,
            loadingLat: PropTypes.number,
            loadingLon: PropTypes.number,
            clientPhone: PropTypes.string,
            timeWindowFrom: PropTypes.string,
            timeWindowTo: PropTypes.string,
            sharedServiceDuration: PropTypes.number,
            serviceDuration: PropTypes.number,
            weight: PropTypes.number,
            cargoCount: PropTypes.number,
            width: PropTypes.number,
            length: PropTypes.number,
            height: PropTypes.number,
          })
        ),
        routeStartDate: PropTypes.string,
      }),
      companyId: PropTypes.number,
      solution: PropTypes.shape({
        trackPoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        })),
        routePoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
          arriveTime: PropTypes.string,
          leaveTime: PropTypes.string,
          orderNum: PropTypes.number,
        })),
        totalTime: PropTypes.number,
      }),
    }),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    refetch: PropTypes.func,
  }),
  taskStatus: PropTypes.shape({
    status: PropTypes.oneOf(['CREATED', 'RUNNING', 'SOLVED', 'PAUSE', 'STOPPED', 'STOPPING'])
  }),
  patchLogisticTask: PropTypes.func,
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
  solveLogisticTask: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  resultPatchLogisticTask: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  stopLogisticTask: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({
        isLoading: PropTypes.bool,
        isSuccess: PropTypes.bool,
      }),
    ])
  ),
};

DeliveryMainPanelBll.defaultProps = {
  logisticTask: null,
  taskStatus: { status: null },
  solveLogisticTask: null,
  resultPatchLogisticTask: null,
  patchLogisticTask: () => {},
  startPolling: () => {},
  stopPolling: () => {},
  stopLogisticTask: null,
};

export default DeliveryMainPanel;
