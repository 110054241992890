import * as React from 'react';
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import styles from './MultilineTextArea.module.css';
import useDebounce from '../../hooks/useDebounce';

function MultilineTextarea(props) {
  const {
    onChange, data, altData, onBlurHandler
  } = props;

  const [value, setValue] = React.useState(data || altData);
  const [addressIsEmpty, setAddressIsEmpty] = React.useState(() => !data);

  const handleCheck = (val) => {
    if (val && data !== val) {
      onChange(val);
    }
  };

  const debouncedCheck = useDebounce((val) => {
    handleCheck(val);
  }, 2000);

  const clickHandler = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const changeHandler = (evt) => {
    const val = evt.target.value;
    setValue(val);
    setAddressIsEmpty(!val);
    debouncedCheck(val);
  };

  const focusHandler = () => {
    if (addressIsEmpty) {
      setValue('');
    }
  };

  const blurHandler = () => {
    if (addressIsEmpty && !value) {
      setValue(altData);
    }
    handleCheck(value);
    onBlurHandler();
  };

  const keyDownHandler = (evt) => {
    if (evt.key === 'Enter' || evt.key === 'Escape') {
      evt.stopPropagation();
      evt.preventDefault();
      if (evt.key === 'Enter') {
        const val = evt.target.value;
        handleCheck(val);
      }
      onBlurHandler();
    }
  };

  return (
    <TextField
      autoFocus
      fullWidth
      multiline
      onClick={clickHandler}
      onChange={changeHandler}
      onFocus={focusHandler}
      onBlur={blurHandler}
      onKeyDown={keyDownHandler}
      variant="standard"
      className={styles.addressInput}
      value={value}
    />
  );
}

MultilineTextarea.propTypes = {
  data: PropTypes.string,
  altData: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlurHandler: PropTypes.func.isRequired,
};

MultilineTextarea.defaultProps = {
  data: '',
  altData: '',
};

export default MultilineTextarea;
