import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormControlLabel,
  List,
  ListItem,
  TextField,
  Typography,
  Alert,
  AlertTitle,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
// import crypto from 'crypto';
import PropTypes from 'prop-types';
import Switch from "@mui/material/Switch";
import { useConfirm } from 'material-ui-confirm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { fromLonLat } from 'ol/proj';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addDays, format, startOfDay } from 'date-fns';
import style from './VehicleRouteFormWidget.module.css';
import {
  getVehicleRoutePlaces,
  getVehicleRouteTracks,
  postVehicleRouteComplex,
  getVehicleRouteBatch,
  getVehicleRoute,
  putVehicleRouteTracks,
  putVehicleRouteComplex,
} from '../../shared/api/api';
import RouteForm from '../../features/vehicleRoute/RouteForm/RouteForm';
import PointForm from '../../features/vehicleRoute/PointForm/PointForm';
import PointCard from '../../entities/vehicles/VehiclesRoute/PointCard/PointCard';
import PointButtons from '../../features/vehicleRoute/PointButtons/PointButtons';
import ControlledTable from '../../shared/ui/ControlledTable/ControlledTable';
import GeoMapWithTiles from '../../features/geo-map/GeoMapWithTiles';
import MarkerVectorLayer from '../../shared/ui/GeoMap/MarkerVectorLayer';
import { calcCenterPolygon, parsePolygon } from '../../shared/geo-map/lib';
import LineVectorLayer from '../../shared/ui/GeoMap/LineVectorLayer';
// eslint-disable-next-line import/no-cycle
import PolygonVectorLayer from '../../shared/ui/GeoMap/PolygonVectorLayer';
import isValidDate from '../../shared/utils/isValidDate';
import useShowError from '../../shared/lib/hooks/useShowError';
import Loading from '../../shared/ui/Loading/Loading';
// eslint-disable-next-line boundaries/element-types
import ScheduleWidget from "../schedule/ScheduleWidget/ScheduleWidget";
import { DEFAULT_CENTER } from '../../features/geo-map/GeoMap.constants';
import VisuallyHiddenInput from '../../shared/ui/VisuallyHiddenInput/VisuallyHiddenInput';

const NEW_PLACE_ID_PREFIX = 'newPlace_';

function calcDelta(date1, date2) {
  return date2.getTime() - date1.getTime();
}

function outOfRange(date) {
  const earliestDate = addDays(startOfDay(new Date()), -7);
  return earliestDate > date;
}

function extractDays(date1, date2) {
  const earliestDate = addDays(startOfDay(new Date()), -7);
  let start = startOfDay(date1);
  start = start < earliestDate ? earliestDate : start;
  const end = startOfDay(date2);
  const res = [start.getTime()];
  while (end > start) {
    start = addDays(start, 1);
    res.push(start.getTime());
  }
  return res;
}

function renderRanges(datesArr) {
  // Будем сравнивать разницу во времени с числом миллисекунд в сутках:
  const MS = 86400000;
  return datesArr.reduce((acc, d, i, arr) => {
    const prev = arr[i - 1];
    const next = arr[i + 1];
    let link = '';
    const prevBiggerThan24h = prev && (d - prev > MS);
    const nextBiggerThan24h = next && (next - d > MS);
    if (prev && next) {
      if (prevBiggerThan24h) {
        if (nextBiggerThan24h) {
          link = `${format(d, 'd.MM')}, `;
        } else {
          link = `${format(d, 'd.MM')}–`;
        }
      } else if (nextBiggerThan24h) {
        link = `${format(d, 'd.MM')}, `;
      }
    } else if (prev) {
      link = `${format(d, 'd.MM')}`;
    } else if (next) {
      if (nextBiggerThan24h) {
        link = `${format(d, 'd.MM')}, `;
      } else {
        link = `${format(d, 'd.MM')}–`;
      }
    } else {
      link = `${format(d, 'd.MM')}`;
    }
    return `${acc}${link}`;
  }, '');
}

function collectRanges(place) {
  // По отсутствию термина "бессрочный" в дате поездки видны пассажиры без основной записи
  return place.reservationDetails
    ? place.reservationDetails.freeDates?.length
      ? `${place.type ? '' : 'бессрочно'} кроме ${renderRanges([...new Set(place.reservationDetails.freeDates.sort())])}`
      : place.reservationDetails.reservedDates?.length
        ? `${renderRanges([...new Set(place.reservationDetails.reservedDates.sort())])}`
        : 'временный'
    : place.type ? '' : 'бессрочно';
}

function transformCargo(vehicleRoutePlaces) {
  const vehicleRoutePlacesSort = vehicleRoutePlaces
    .sort((a, b) => a.cargo.title.localeCompare(b.cargo.title));
  return vehicleRoutePlacesSort
    .reduce((acc, item) => {
      // Группируем одинаковых пассажиров
      const alreadyAddedItem = acc.find((a) => (
        a.find((addedItem) => addedItem.cargo.compCargoId === item.cargo.compCargoId)
      ));
      if (alreadyAddedItem) {
        alreadyAddedItem.push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, [])
    .map((itemArr) => {
      // Соединяем одинаковых пассажиров в одну запись
      const basicItem = itemArr.find((item) => !item.type)
        || (itemArr[0].type === 'RESERVE_TYPE_RESERVED' ? {} : itemArr[0]);
      const mapped = itemArr.reduce((acc, item) => {
        const res = { ...item, ...acc };
        if (item.type) {
          const noDates = !item.startDate || !item.finishDate;
          const startDate = !noDates && new Date(item.startDate);
          const endDate = !noDates && new Date(item.finishDate);
          if (!noDates && outOfRange(endDate)) {
            return acc;
          }
          res.reservationDetails = res.reservationDetails ? { ...res.reservationDetails } : {};
          const itemDayRange = noDates ? [] : extractDays(startDate, endDate);
          if (item.type === 'RESERVE_TYPE_RESERVED') {
            res.reservationDetails.reservedDates = res.reservationDetails.reservedDates || [];
            res.reservationDetails.reservedDates.push(...itemDayRange);
          } else if (item.type === 'RESERVE_TYPE_FREE') {
            res.reservationDetails.freeDates = res.reservationDetails.freeDates || [];
            res.reservationDetails.freeDates.push(...itemDayRange);
          }
        }
        return res;
      }, basicItem);
      return mapped;
    })
    .filter((m) => !!m.cargo)
    .map((vehicleRoutePlace) => ({
      ...vehicleRoutePlace,
      titleTags: (
        <div>
          {vehicleRoutePlace.cargo?.title}
          {' '}
          {vehicleRoutePlace.cargo?.tags ? vehicleRoutePlace.cargo.tags.split(',').map((tag) => (
            <Chip label={tag.trim()} color="primary" size="small" sx={{ marginRight: '5px' }} />
          )) : null}
        </div>),
      title: vehicleRoutePlace.cargo?.title,
      dates: collectRanges(vehicleRoutePlace),
      comment: vehicleRoutePlace.cargo?.comment,
      status: vehicleRoutePlace.status === 'VEHICLE_ROUTE_PLACE_ONBOARD' ? <CheckCircleIcon color="primary" /> : '',
    }));
}

const orderDateNamesStart = [
  'startTime-route',
  'startTimeUntil-route',
];

const orderDateNamesFinish = [
  'finishTime-route',
  'finishTimeUntil-route',
];

const orderDateNamesPoint = [
  'arriveTime',
  'arriveTimeUntil',
  'stopTime',
  'stopTimeUntil',
  'leaveTime',
  'leaveTimeUntil',
];

const orderDateNamesPointBack = [
  'backArriveTime',
  'backArriveTimeUntil',
  'backStopTime',
  'backStopTimeUntil',
  'backLeaveTime',
  'backLeaveTimeUntil',
];
// Формирует правильный порядок всех полей даты формы
const createOrderDateNames = (length) => {
  const orderDateNamesPoints = [];
  const orderDateNamesPointsBack = [];
  for (let i = 0; i < length; i += 1) {
    orderDateNamesPoint.forEach((name) => {
      orderDateNamesPoints.push(`${name}-${i}`);
    });
    orderDateNamesPointBack.forEach((name) => {
      orderDateNamesPointsBack.push(`${name}-${length - i - 1}`);
    });
  }
  return [
    ...orderDateNamesStart,
    ...orderDateNamesPoints,
    ...orderDateNamesPointsBack,
    ...orderDateNamesFinish,
  ];
};

const shiftDates = (vehicleRoute, points, updatedDate) => {
  let newPointsDates = {};
  const newRouteDates = {};
  const orderDateNames = createOrderDateNames(points.length);
  let delta = isValidDate(updatedDate.prevDate)
    ? calcDelta(updatedDate.prevDate, updatedDate.newDate)
    : null;

  const shiftDate = (shiftedDate) => {
    let newDate = null;
    if (isValidDate(shiftedDate)) {
      // если prevDate был валидный
      if (delta !== null) {
        newDate = new Date(shiftedDate.getTime() + delta);
      } else {
        // иначе проверям разницу между обновляемой датой и сдвигаемой датой
        const newDelta = calcDelta(shiftedDate, updatedDate.newDate);
        if (newDelta >= 0) {
          // если новая дата больше обновляемой, меняем обновляемую в новую
          newDate = updatedDate.newDate;
          // и обновляем delta для последующих обновляемых дат
          delta = newDelta;
        }
      }
    }
    return newDate;
  };

  // находим индекс обновляемой даты и перебираем с нее
  const dateNameIndex = orderDateNames.indexOf(updatedDate.name);
  for (let i = dateNameIndex + 1; i < orderDateNames.length; i += 1) {
    const name = orderDateNames[i];
    const fullName = name.split('-');
    const dateName = fullName[0];
    const index = fullName[1];
    // если дата маршрута, сдвигаем дату маршрута
    if (index === 'route') {
      const shiftedDate = vehicleRoute[dateName];
      const newDate = shiftDate(shiftedDate);
      if (newDate) {
        newRouteDates[dateName] = newDate;
      }
    } else {
      // если дата точки, сдвигаем дату для точки
      const shiftedDate = points[index][dateName];
      const newDate = shiftDate(shiftedDate);
      if (newDate) {
        newPointsDates = {
          ...newPointsDates,
          [index]: {
            ...newPointsDates[index],
            [dateName]: newDate,
          }
        };
      }
    }
  }
  return { newRouteDates, newPointsDates };
};

export function findCenter(coordinates) {
  if (coordinates.length === 0) {
    return null; // Возвращаем null в случае пустого массива
  }

  // Инициализируем переменные для суммы широты и долготы
  let latSum = 0;
  let lonSum = 0;

  // Суммируем координаты
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < coordinates.length; i++) {
    latSum += coordinates[i].lat;
    lonSum += coordinates[i].lon;
  }

  // Находим среднее значение широты и долготы
  const latCenter = latSum / coordinates.length;
  const lonCenter = lonSum / coordinates.length;

  // Возвращаем координаты центра
  return { lat: latCenter, lon: lonCenter };
}

const headCells = (isPassenger) => [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: isPassenger ? 'ФИО' : 'Название',
  },
  ...(isPassenger ? [{
    id: 'dates',
    numeric: false,
    disablePadding: false,
    disableSort: true,
    label: 'Дата поездки',
  }] : []),
  {
    id: 'comment',
    numeric: false,
    disablePadding: false,
    label: 'Комментарии',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: isPassenger ? 'В салоне' : 'На борту',
  },
];

const displayedCells = (isPassenger) => [
  'titleTags',
  ...(isPassenger ? ['dates'] : []),
  'comment',
  'status',
];

// Новые поля для формы маршрута
const newVehicleRouteInputs = (nowDate = null) => ({
  name: '',
  vehicle: null,
  vehicleInput: '',
  startTime: nowDate,
  startTimeUntil: nowDate,
  finishTime: nowDate,
  finishTimeUntil: nowDate,
  isAutostarted: false,
  isRoundTrip: false,
  isAutoShifted: false,
});

// Новые поля для формы точки маршрута туда
const newPointInputs = (nowDate = null) => ({
  arriveTime: nowDate,
  arriveTimeUntil: nowDate,
  leaveTime: nowDate,
  leaveTimeUntil: nowDate,
  isArriveAlert: false,
  isArriveInform: false,
  isLeaveAlert: false,
  isLeaveInform: false,
  stopTime: null,
  stopTimeUntil: null,
  isSkip: false,
});

// Новые поля для формы точки маршрута обратно
const newBackPointInputs = (nowDate = null) => ({
  backArriveTime: nowDate,
  backArriveTimeUntil: nowDate,
  backLeaveTime: nowDate,
  backLeaveTimeUntil: nowDate,
  backStopTime: null,
  backStopTimeUntil: null,
  isBackArriveAlert: false,
  isBackArriveInform: false,
  isBackLeaveAlert: false,
  isBackLeaveInform: false,
  isBackSkip: true,
});

function PointItem({
  index,
  point,
  points,
  setPoints,
  companyId,
  rememberedDate,
  automaticTimeShift
}) {
  const [isOpen, setOpen] = useState(false);
  const [isEditComment, setIsEditComment] = useState(false);

  const onClickComment = () => {
    setIsEditComment((prev) => !prev);
  };

  const onBlurComment = () => {
    setIsEditComment(false);
  };

  return (
    <Box
      sx={{ width: "100%" }}
      key={point.id}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => (!isOpen
            ? setOpen((prev) => !prev)
            : setTimeout(() => (() => {
              setOpen((prev) => !prev);
            }), 0))}
        >
          <Box>
            <PointCard
              point={point}
              buttons={(
                <PointButtons
                  point={point}
                  setPoints={setPoints}
                />
                  )}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isOpen && (
          <Box>
            <ListItem>
              <PointForm
                index={index}
                pointsCount={points.length}
                points={points}
                prevPoint={points[index - 1]}
                pointInputs={newPointInputs(new Date())}
                backPointInputs={newBackPointInputs(new Date())}
                point={point}
                setPoints={setPoints}
                companyId={companyId}
                rememberedDate={rememberedDate}
                automaticTimeShift={automaticTimeShift}
                isEditComment={isEditComment}
                onBlurComment={onBlurComment}
                onClickComment={onClickComment}
                buttons={(
                  <PointButtons
                    point={point}
                    setPoints={setPoints}
                    onClickComment={onClickComment}
                  />
                )}
              />
            </ListItem>
          </Box>
          )}
        </AccordionDetails>
      </Accordion>

    </Box>
  );
}

PointItem.propTypes = {
  index: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  point: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  points: PropTypes.array,
  setPoints: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  companyId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  rememberedDate: PropTypes.object,
  automaticTimeShift: PropTypes.bool
};

PointItem.defaultProps = {
  index: null,
  point: null,
  points: null,
  setPoints: null,
  companyId: null,
  rememberedDate: null,
  automaticTimeShift: null,
};

// Форма, обединяющая форму маршрута, карточку и форму точек
function VehicleRouteFormWidget(props) {
  const {
    updateRoutes,
    isCopy,
    isEdit,
    isCreate,
    companyId,
  } = props;

  const [dataVehicleRoute, setDataVehicleRoute] = useState();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const route = location.state;
  const { vehicleRouteId } = params;
  const [selectedRoute, setSelectedRoute] = useState(route);
  const [isErrorRoute, setIsErrorRoute] = useState(false);
  const [isLoadingRoute, setIsLoadingRoute] = useState(false);
  const [fileIsLoading, setFileIsLoading] = useState(false);

  const [selectedItemRetryStore, setSelectedItemRetryStore] = useState('');
  const [scheduleIsReady, setScheduleIsReady] = useState(true);

  useEffect(() => {
    if (!route && !isCreate) {
      setIsLoadingRoute(true);
      getVehicleRoute(vehicleRouteId)
        .then((res) => {
          setIsErrorRoute(false);
          setSelectedRoute(res);
        })
        .catch(() => {
          setIsErrorRoute(true);
        })
        .finally(() => {
          setIsLoadingRoute(false);
        });
    }
  }, [vehicleRouteId, route, isCreate]);

  useEffect(() => {
    if (vehicleRouteId) {
      getVehicleRoute(vehicleRouteId).then((e) => {
        setDataVehicleRoute(e);
      });
    }
  }, [vehicleRouteId]);

  // Поля ввода для маршрута
  const [vehicleRoute, setVehicleRoute] = useState(null);
  // Поля ввода для точек (массив, изначально с одним дефолтным объектом точки)
  const [points, setPoints] = useState([]);
  // Автоматический сдвиг времени
  const [isAutomaticTimeShift, setIsAutomaticTimeShift] = useState(true);

  const confirm = useConfirm();
  const showError = useShowError();

  const [errors, setErrors] = useState({ ...newVehicleRouteInputs() });

  const [
    countOfPassengerPlaces, setCountOfPassengerPlaces
  ] = useState(vehicleRoute?.passangerPlaces || 0);
  const [countOfCargoPlaces, setCountOfCargoPlaces] = useState(vehicleRoute?.passangerPlaces || 0);

  const collectEditedPoints = () => {
    // Точки, которые нужно добавить к маршруту
    const newPoints = [];
    points.forEach((point, i) => {
      if (point.geoPoint || point.type === 'POINT' || (point?.latitude && point?.longitude)) {
        const idPoint = point.vroutePointId ? { vroutePointId: point.vroutePointId } : {};
        const newPoint = {
          // сразу тело запроса для точки делаем, при этом обновляем (если изменился) порядок,
          // указывая orderNum по индексу нового массива точек
          ...idPoint,
          route: { vehicleRouteId: vehicleRoute.vehicleRouteId },
          geoName:
            point?.type === "POINT_GEONAME"
              ? { geonameId: point.geoPoint.geonameId || point.geoPoint.id }
              : null,
          geoZone:
            point?.type === "POINT_GEOZONE"
              ? { geozoneId: point.geoPoint.geozoneId || point.geoPoint.id }
              : null,
          orderNum: i,
          arriveTime: point.arriveTime,
          arriveTimeUntil: point.arriveTimeUntil,
          leaveTime: point.leaveTime,
          leaveTimeUntil: point.leaveTimeUntil,
          stopTime: point.stopTime,
          stopTimeUntil: point.stopTimeUntil,
          isArriveInform: point.isArriveInform,
          isArriveAlert: point.isArriveAlert,
          isLeaveInform: point.isLeaveInform,
          isLeaveAlert: point.isLeaveAlert,
          isPublic: point.isPublic,
          isTemplate: point.isTemplate,
          isSkip: point.isSkip,
          // Обратно
          backArriveTime: point.backArriveTime,
          backArriveTimeUntil: point.backArriveTimeUntil,
          backLeaveTime: point.backLeaveTime,
          backLeaveTimeUntil: point.backLeaveTimeUntil,
          backStopTime: point.backStopTime,
          backStopTimeUntil: point.backStopTimeUntil,
          isBackArriveAlert: point.isBackArriveAlert,
          isBackArriveInform: point.isBackArriveInform,
          isBackLeaveAlert: point.isBackLeaveAlert,
          isBackLeaveInform: point.isBackLeaveInform,
          isBackStopInform: point.isBackStopInform,
          isBackSkip: point.isBackSkip,
          longitude: point.lon ? point.lon / 10000000 : point.longitude,
          latitude: point.lat ? point.lat / 10000000 : point.latitude,
          type: point.type,
          comment: point.comment,
        };
        newPoints.push(newPoint);
      }
    });

    return newPoints;
  };

  const [vehicleRoutePlaces, setVehicleRoutePlaces] = useState(null);

  // Нажатие на кнопку сохранения
  const save = () => {
    let scheduleData = {};
    if (!selectedItemRetryStore?.includes("NORETRY")) {
      scheduleData = {
        schedule: selectedItemRetryStore,
        countOfRepeat: 0,
      };
    }
    const savingRoutePlaces = vehicleRoutePlaces?.length
      ? [...vehicleRoutePlaces].map(({ vroutePlaceId, ...rest }, i) => (
        `${vroutePlaceId}`.indexOf(NEW_PLACE_ID_PREFIX) === 0 ? rest : vehicleRoutePlaces[i]
      )) : null;
    // Если происходит создание маршрута
    if (isCreate || isCopy) {
      // Создание маршрута
      postVehicleRouteComplex({
        status: 'VROUTE_STATUS_PLANNED',
        company: { companyId },
        name: vehicleRoute.name,
        isAutostarted: vehicleRoute.isAutostarted,
        isRoundTrip: vehicleRoute.isRoundTrip,
        isAutoShifted: vehicleRoute.isAutoShifted,
        finishTime: vehicleRoute.finishTime,
        finishTimeUntil: vehicleRoute.finishTimeUntil,
        startTime: vehicleRoute.startTime,
        startTimeUntil: vehicleRoute.startTimeUntil,
        vehicle: vehicleRoute.vehicle ? { vehicleId: vehicleRoute.vehicle.vehicleId } : null,
        isPublic: vehicleRoute.isPublic,
        isTemplate: vehicleRoute.isTemplate,
        isCycle: vehicleRoute.isCycle,
        passangerPlaces: countOfPassengerPlaces,
        cargoPlaces: countOfCargoPlaces,
        ...scheduleData,
        routePoints: collectEditedPoints(),
        routePlaces: savingRoutePlaces,
      })
        .then(() => {
          // При успешном сохранении закрываем форму
          updateRoutes();
          navigate(`/companies/${companyId}/routes`);
        })
        .catch(() => { showError('Ошибка при создании маршрута'); });
    } else {
      const bodyRoute = {
        vehicleRouteId: vehicleRoute.vehicleRouteId,
        status: vehicleRoute.status,
        company: { companyId },
        name: vehicleRoute.name,
        isAutostarted: vehicleRoute.isAutostarted,
        isRoundTrip: vehicleRoute.isRoundTrip,
        isAutoShifted: vehicleRoute.isAutoShifted,
        finishTime: vehicleRoute.finishTime,
        finishTimeUntil: vehicleRoute.finishTimeUntil,
        startTime: vehicleRoute.startTime,
        startTimeUntil: vehicleRoute.startTimeUntil,
        isPublic: vehicleRoute.isPublic,
        isTemplate: vehicleRoute.isTemplate,
        isCycle: vehicleRoute.isCycle,
        vehicle: (!vehicleRoute.isTemplate && vehicleRoute.vehicle?.vehicleId)
          ? { vehicleId: vehicleRoute.vehicle?.vehicleId } : null,
        passangerPlaces: countOfPassengerPlaces,
        cargoPlaces: countOfCargoPlaces,
        ...scheduleData,
        routePoints: collectEditedPoints(),
        routePlaces: savingRoutePlaces,
      };
      // Сохранение изменений маршрута
      putVehicleRouteComplex(bodyRoute)
        .then(() => {
          // При успешном сохранении закрываем форму
          updateRoutes();
          navigate(`/companies/${companyId}/routes`);
        })
        .catch(() => { showError('Ошибка при сохранении маршрута'); });
    }
  };

  // Установка состояния полей ввода в зависимости от того,
  // редактируется ли маршрут или создается новый
  useEffect(() => {
    // Если есть выбранный маршрут
    if (selectedRoute) {
      setPoints(() => selectedRoute.routePoints.map((routePoint) => ({
        ...newPointInputs(),
        ...newBackPointInputs(),
        ...routePoint,
        id: routePoint.vroutePointId,
        geoPoint: routePoint.geoName || routePoint.geoZone,
        lat: routePoint.latitude * 10000000,
        lon: routePoint.longitude * 10000000
      })));
      setVehicleRoute(() => ({
        ...newVehicleRouteInputs(),
        ...selectedRoute,
        vehicle: isCopy ? null : selectedRoute.vehicle,
        startTime: selectedRoute.startTime,
        startTimeUntil: selectedRoute.startTimeUntil,
        finishTime: selectedRoute.finishTime,
        finishTimeUntil: selectedRoute.finishTimeUntil,
      }));
    } else {
      // Выбранного маршрута нет, значит происходит создание нового
      setPoints([{
        // eslint-disable-next-line no-undef
        id: crypto.randomUUID(),
        geoPoint: null,
        ...newPointInputs(new Date()),
        ...newBackPointInputs(new Date()),
        type: null,
        lat: null,
        lon: null
      }]);
      setVehicleRoute({ ...newVehicleRouteInputs(new Date()) });
    }
  }, [selectedRoute, isCopy]);

  const rememberedDate = useRef({
    name: '',
    prevDate: null,
    newDate: null,
  });

  const automaticTimeShift = () => {
    if (isAutomaticTimeShift) {
      const updatedDate = rememberedDate.current;

      if (!isValidDate(updatedDate.newDate)) {
        return;
      }

      const { newRouteDates, newPointsDates } = shiftDates(vehicleRoute, points, updatedDate);
      setVehicleRoute((prev) => ({
        ...prev,
        ...newRouteDates,
      }));
      setPoints((prev) => {
        const newPoints = [...prev];
        // Редактируемую точку изменяем отдельно, т.к. может содержать устаревшие данные
        const index = updatedDate.pointIndex;
        newPoints[index] = { ...prev[index], ...newPointsDates[index] };
        // Обновляем остальные точки
        Object.keys(newPointsDates).forEach((i) => {
          const newDates = newPointsDates[i];
          newPoints[i] = { ...prev[i], ...newDates };
        });
        return newPoints;
      });

      // Сбрасываем запомненную дату
      rememberedDate.current = {
        ...rememberedDate.current,
        prevDate: rememberedDate.current.newDate,
        name: '',
      };
    }
  };

  const [passengersTableData, setPassengersTableData] = useState(null);
  const [cargoTableData, setCargoTableData] = useState(null);
  const [addCargoText, setAddCargoText] = useState('');
  const [addPassengerText, setAddPassengerText] = useState('');

  useEffect(() => {
    if (vehicleRouteId) {
      getVehicleRoutePlaces({ routeId: vehicleRouteId }).then((res) => {
        setVehicleRoutePlaces(res);
      });
    }
  }, [vehicleRouteId]);

  useEffect(() => {
    if (vehicleRoutePlaces?.length) {
      const newPlacesTableData = transformCargo(vehicleRoutePlaces);

      setPassengersTableData(newPlacesTableData.filter((p) => p.cargo?.cargoType === 'CARGO_TYPE_PASSENGER'));
      setCargoTableData(newPlacesTableData.filter((p) => p.cargo?.cargoType === 'CARGO_TYPE_CARGO'));
      // .map((td) => ({
      //   ...td,
      //   dimentions: [td.cargo.width, td.cargo.length, td.cargo.height]
      //     .filter((d) => d).join('×'),
      //   weight: td.cargo.weight,
      // })));
    } else {
      setPassengersTableData(null);
      setCargoTableData(null);
    }
  }, [vehicleRoutePlaces]);

  const [vehicleRouteTracks, setVehicleRouteTracks] = useState(null);
  const [triggerRouteTrackFlag, setTriggerRouteTrackFlag] = useState(false);

  useEffect(() => {
    if (vehicleRouteId) {
      getVehicleRouteTracks({ routeId: vehicleRouteId }).then((res) => {
        setVehicleRouteTracks(res);
      });
    }
  }, [vehicleRouteId, triggerRouteTrackFlag]);

  const pointsMarkers = useMemo(() => {
    let newPointsMarkers;
    selectedRoute?.routePoints?.forEach((routePoint, i) => {
      if (routePoint.geoName) {
        const newPointMarker = {
          id: i,
          name: routePoint.geoName?.name || 'Не указано',
          coordinates: fromLonLat([
            Number(routePoint.geoName.longitude),
            Number(routePoint.geoName.latitude)
          ])
        };
        newPointsMarkers = [
          ...(newPointsMarkers || []),
          newPointMarker,
        ];
      }
    });
    return newPointsMarkers;
  }, [selectedRoute]);

  const geozonesPolygons = useMemo(() => {
    let newGeozonesPolygons;
    selectedRoute?.routePoints?.forEach((routePoint, i) => {
      if (routePoint.geoZone) {
        const polygonCoords = parsePolygon(routePoint.geoZone.area);
        if (!polygonCoords) {
          // eslint-disable-next-line no-console
          console.error(`Некорректные координаты геозоны: "${routePoint.geoZone.name}" - geozoneId: ${routePoint.geoZone.geozoneId}`);
          return;
        }
        const newGeozonePolygon = {
          id: i,
          name: routePoint.geoZone?.name || 'Не указано',
          coordinates: polygonCoords
        };
        newGeozonesPolygons = [
          ...(newGeozonesPolygons || []),
          newGeozonePolygon,
        ];
      }
    });
    return newGeozonesPolygons;
  }, [selectedRoute]);

  const extent = useMemo(() => {
    const pointsPolygons = geozonesPolygons?.map((geozone) => ({
      ...geozone,
      coordinates: calcCenterPolygon(geozone.coordinates),
    }));
    const allPoints = [
      ...(pointsMarkers || []),
      ...(pointsPolygons || []),
    ];

    if (allPoints.length < 2) {
      return null;
    }

    let minx = allPoints[0].coordinates[0]; // долгота
    let maxx = allPoints[0].coordinates[0];
    let miny = allPoints[0].coordinates[1]; // широта
    let maxy = allPoints[0].coordinates[1];
    // Определение границ по всем точкам маршрута
    allPoints.forEach((point) => {
      point.coordinates.forEach((c, j) => {
        if (j === 0) {
          if (c > maxx) {
            maxx = c;
          }
          if (c < minx) {
            minx = c;
          }
        } else {
          if (c > maxy) {
            maxy = c;
          }
          if (c < miny) {
            miny = c;
          }
        }
      });
    });
    return [minx, miny, maxx, maxy];
  }, [pointsMarkers, geozonesPolygons]);

  const addCargo = (type) => {
    const [setFunc, text] = {
      CARGO_TYPE_PASSENGER: [setPassengersTableData, addPassengerText],
      CARGO_TYPE_CARGO: [setCargoTableData, addCargoText],
    }[type];
    getVehicleRouteBatch(text, type).then((res) => {
      const newPlacesData = [...vehicleRoutePlaces, ...res.map((r) => ({ ...r, vroutePlaceId: `${NEW_PLACE_ID_PREFIX}${r.cargo.compCargoId}` }))];
      const newPlacesDataMap = newPlacesData.reduce((acc, item) => (
        { ...acc, [item.cargo.compCargoId]: item }
      ), {});
      const newPlacesTableData = transformCargo(newPlacesData);
      if (newPlacesTableData.length) {
        setVehicleRoutePlaces(newPlacesTableData.map((tableItem) => (
          newPlacesDataMap[tableItem.cargo.compCargoId]
        )));
        setFunc(newPlacesTableData.filter((p) => p.cargo?.cargoType === type));
      }
    });
  };

  const onClickAddPassenger = () => {
    addCargo('CARGO_TYPE_PASSENGER');
  };
  const onClickAddCargo = () => {
    addCargo('CARGO_TYPE_CARGO');
  };

  // Текущий открытый аккордион
  const [expanded, setExpanded] = useState('Route');

  // Изменение открытого аккордиона
  const changeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setCountOfPassengerPlaces(vehicleRoute?.passangerPlaces || 0);
  }, [vehicleRoute?.passangerPlaces]);

  useEffect(() => {
    setCountOfCargoPlaces(vehicleRoute?.cargoPlaces || 0);
  }, [vehicleRoute?.cargoPlaces]);

  const postVehicleRouteFile = useCallback((selectedFile) => {
    if (selectedFile) {
      setFileIsLoading(true);
      putVehicleRouteTracks(vehicleRouteId, selectedFile)
        .then(() => {
          // запрашиваем новый трек
          setTriggerRouteTrackFlag((prev) => !prev);
        })
        .catch((error) => {
          showError('Ошибка при загрузке файла');
          // eslint-disable-next-line no-console
          console.error('Ошибка при загрузке файла:', error);
        })
        .finally(() => setFileIsLoading(false));
    }
  }, [companyId, showError]);

  if (isLoadingRoute) {
    return (
      <Loading />
    );
  }

  if (isErrorRoute) {
    return (
      <Alert severity="error" sx={{ marginTop: '20px' }}>
        <AlertTitle>Ошибка</AlertTitle>
        Ошибка при загрузке данных
      </Alert>
    );
  }

  const pointsOrder = points.sort((a, b) => a.orderNum - b.orderNum);

  const centerOfPoints = findCenter(pointsOrder);

  const center = (pointsOrder?.length && centerOfPoints.lat && centerOfPoints.lon)
    ? fromLonLat(
      [centerOfPoints.lon / 10000000, centerOfPoints.lat / 10000000]
    ) : undefined;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const type = selectedFile.name?.split('.');

      // Проверяем размер файла (не более 2 МБ)
      if (selectedFile.size > 2 * 1024 * 1024) {
        confirm({
          title: 'Ошибка',
          confirmationText: 'Да',
          cancellationText: 'Отмена',
          description: 'Лимит превышен. Файл должен быть не более 2 МБ.',
          buttonOrder: ['cancel'],
        })
          .then(() => {})
          .catch(() => { });
      } else if (type[type.length - 1]?.toLowerCase() === 'gpx' || type[type.length - 1]?.toLowerCase() === 'kml') {
        const formData = new FormData();
        formData.append('file', selectedFile);
        postVehicleRouteFile(formData);
      } else {
        confirm({
          title: 'Ошибка',
          confirmationText: 'Да',
          cancellationText: 'Отмена',
          description: 'Ошибка формата. Файл должен быть .gpx или .kml',
          buttonOrder: ['cancel'],
        })
          .then(() => {})
          .catch(() => { });
      }
    }
  };

  const сhangeSсheduleHandler = (value, saveAvailable) => {
    setSelectedItemRetryStore(value);
    setScheduleIsReady(saveAvailable);
  };

  const deletePlace = (vroutePlaceId) => {
    const placeIndex = vehicleRoutePlaces.findIndex((place) => (
      place.vroutePlaceId === vroutePlaceId
    ));
    const newVehicleRoutePlaces = [...vehicleRoutePlaces];
    newVehicleRoutePlaces.splice(placeIndex, 1);
    setVehicleRoutePlaces(newVehicleRoutePlaces);
    const newPlacesTableData = transformCargo(vehicleRoutePlaces);
    setPassengersTableData(newPlacesTableData.filter((p) => p.cargo?.cargoType === 'CARGO_TYPE_PASSENGER'));
    setCargoTableData(newPlacesTableData.filter((p) => p.cargo?.cargoType === 'CARGO_TYPE_CARGO'));
  };

  return vehicleRoute
    ? (
      <Box className={style.vehicleRouteFormWidget}>
        <Box className={style.accordions}>
          <Accordion square expanded={expanded === 'Route'} onChange={changeAccordion('Route')}>
            <AccordionSummary>
              <Typography>
                Маршрут
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <FormControlLabel
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: "-20px",
                  }}
                  control={(
                    <Switch
                      checked={isAutomaticTimeShift}
                      color="error"
                      onChange={() => {
                        setIsAutomaticTimeShift((prev) => !prev);
                      }}
                    />
                    )}
                  label="Автоматический сдвиг времени"
                />
                <Typography variant="h6" gutterBottom component="div" sx={{ color: 'text.secondary' }}>
                  {isEdit ? 'Редактировать маршрут' : 'Новый маршрут'}
                </Typography>
              </Box>

              {vehicleRoute && (
              <RouteForm
                errors={errors}
                setErrors={setErrors}
                companyId={companyId}
                vehicleRoute={vehicleRoute}
                setVehicleRoute={setVehicleRoute}
                setPoints={setPoints}
                rememberedDate={rememberedDate}
                automaticTimeShift={automaticTimeShift}
              />
              )}
              <Box className={style.points}>
                <List
                  className={style.pointsList}
                >
                  {points.length
                    ? points.map((point, index) => (
                      <PointItem
                        index={index}
                        point={point}
                        points={points}
                        setPoints={setPoints}
                        companyId={companyId}
                        rememberedDate={rememberedDate}
                        automaticTimeShift={automaticTimeShift}
                      />
                    ))
                    : null}
                  <Button
                    sx={{ width: '100%' }}
                    variant="outlined"
                    startIcon={<AddLocationAltIcon />}
                    onClick={() => {
                      const newPoint = {
                        // eslint-disable-next-line no-undef
                        id: crypto.randomUUID(),
                        geoPoint: null,
                        ...newPointInputs(new Date()),
                        ...newBackPointInputs(new Date()),
                      };
                      setPoints((prev) => [...prev, newPoint]);
                    }}
                  >
                    Новая точка
                  </Button>
                </List>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!vehicleRoute.vehicleRouteId || isCopy} square expanded={expanded === 'Schedule'} onChange={changeAccordion('Schedule')}>
            <AccordionSummary>
              <Typography>
                Расписание
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ScheduleWidget
                onChangeSсhedule={сhangeSсheduleHandler}
                scheduleValue={dataVehicleRoute?.schedule || ""}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion square expanded={expanded === 'Map'} onChange={changeAccordion('Map')}>
            <AccordionSummary>
              <Typography>
                На карте
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                fileIsLoading
                  ? (
                    <CircularProgress
                      className={style.uploadBtn}
                      sx={{ position: 'absolute', padding: '8px' }}
                      size={40}
                    />
                  )
                  : (
                    <Tooltip title="Загрузить трек">
                      <IconButton
                        disabled={!vehicleRoute.vehicleRouteId}
                        className={style.uploadBtn}
                        sx={{ position: 'absolute' }}
                        color="primary"
                        component="label"
                        role={undefined}
                        on
                      >
                        <UploadFileIcon />
                        <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".gpx, .kml" />
                      </IconButton>
                    </Tooltip>
                  )
              }
              <GeoMapWithTiles
                styles={style.geoMap}
                disableFullScreen
                extent={extent}
                isOffButtonFollowMode
                center={center || DEFAULT_CENTER}
                zoom={center ? 13 : 3}
              >
                {vehicleRouteTracks?.map((vehicleRouteTrack) => (
                  <LineVectorLayer
                    key={vehicleRouteTrack.id}
                    coordinates={vehicleRouteTrack.track}
                  />
                ))}
                {pointsOrder.map((pointMarker, i) => (
                  <MarkerVectorLayer
                    zIndex={9}
                    key={`point-number-${i + 1}`}
                    coordinates={fromLonLat(
                      [pointMarker.lon / 10000000, pointMarker.lat / 10000000]
                    )}
                    point
                    numerary
                    numeraryInAnotherLayout
                    title={pointMarker.name}
                    text={`${pointMarker.orderNum}`}
                  />
                ))}

                {pointsMarkers?.map((pointMarker) => (
                  <MarkerVectorLayer
                    key={pointMarker.id}
                    coordinates={pointMarker.coordinates}
                    point
                    numerary
                    numeraryInAnotherLayout
                    title={pointMarker.name}
                    text={`${pointMarker.id + 1}`}
                  />
                ))}
                {geozonesPolygons?.map((geoZone) => (
                  <PolygonVectorLayer
                    key={geoZone.id}
                    zIndex={10}
                    text={geoZone.name}
                    coordinates={geoZone.coordinates}
                    numerary
                    numeraryText={`${geoZone.id + 1}`}
                  />
                ))}

              </GeoMapWithTiles>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!vehicleRoute.vehicleRouteId || isCopy} square expanded={expanded === 'Passangers'} onChange={changeAccordion('Passangers')}>
            <AccordionSummary>
              <Typography>
                Пассажиры
                {' '}
                (
                {passengersTableData?.length || '0'}
                )
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={style.passengers}>
                <Box className={style.controls}>
                  <Box className={style.colntrol}>
                    <TextField
                      className={style.addText}
                      variant="outlined"
                      size="small"
                      value={addPassengerText}
                      onChange={(e) => {
                        setAddPassengerText(e.target.value);
                      }}
                    />
                    <Button variant="contained" disabled={addPassengerText.length < 2} onClick={onClickAddPassenger}>
                      Добавить
                    </Button>
                  </Box>
                </Box>
                <ControlledTable
                  rows={passengersTableData || []}
                  headCells={headCells(true)}
                  displayedCells={displayedCells(true)}
                  countRowsPerPage={10}
                  selected={[]}
                  setSelected={() => { }}
                  controls
                  titleDeleteButton="Снять с маршрута"
                  onDelete={(row) => {
                    confirm({
                      title: 'Снять с маршрута?',
                      confirmationText: 'Да',
                      cancellationText: 'Отмена',
                      description: `Вы действительно хотите снять пассажира «${row?.title}» с маршрута?`
                    })
                      .then(() => deletePlace(row.vroutePlaceId))
                      .catch(() => { });
                  }}
                />
                <Box className={style.colntrol}>
                  <FormControl
                    variant="standard"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between'
                    }}
                    error={Number(countOfPassengerPlaces) < 0 || countOfPassengerPlaces === ''}
                  >
                    <FormLabel>
                      Общее кол-во мест на маршруте
                    </FormLabel>
                    <Input
                      id="countOfPlaces"
                      size="small"
                      value={countOfPassengerPlaces}
                      onChange={(event) => setCountOfPassengerPlaces(event.target.value)}
                      sx={{ width: '60px' }}
                      type="number"
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' }
                      }}
                      error={Number(countOfPassengerPlaces) < 0 || countOfPassengerPlaces === ''}
                    />
                  </FormControl>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={!vehicleRoute.vehicleRouteId || isCopy} square expanded={expanded === 'Cargo'} onChange={changeAccordion('Cargo')}>
            <AccordionSummary>
              <Typography>
                Грузы
                {' '}
                (
                {cargoTableData?.length || '0'}
                )
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={style.passengers}>
                <Box className={style.controls}>
                  <Box className={style.colntrol}>
                    <TextField
                      className={style.addText}
                      variant="outlined"
                      size="small"
                      value={addCargoText}
                      onChange={(e) => {
                        setAddCargoText(e.target.value);
                      }}
                    />
                    <Button variant="contained" disabled={addCargoText.length < 2} onClick={onClickAddCargo}>
                      Добавить
                    </Button>
                  </Box>
                </Box>
                <ControlledTable
                  rows={cargoTableData || []}
                  headCells={headCells(false)}
                  displayedCells={displayedCells(false)}
                  countRowsPerPage={10}
                  selected={[]}
                  setSelected={() => { }}
                  controls
                  titleDeleteButton="Снять с маршрута"
                  onDelete={(row) => {
                    confirm({
                      title: 'Снять с маршрута?',
                      confirmationText: 'Да',
                      cancellationText: 'Отмена',
                      description: `Вы действительно хотите снять груз «${row?.title}» с маршрута?`
                    })
                      .then(() => deletePlace(row.vroutePlaceId))
                      .catch(() => { });
                  }}
                />
                <Box className={style.colntrol}>
                  <FormControl
                    variant="standard"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '20px',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between'
                    }}
                    error={Number(countOfCargoPlaces) < 0 || countOfCargoPlaces === ''}
                  >
                    <FormLabel>
                      Общее кол-во мест на маршруте
                    </FormLabel>
                    <Input
                      id="countOfPlaces"
                      size="small"
                      value={countOfCargoPlaces}
                      onChange={(event) => setCountOfCargoPlaces(event.target.value)}
                      sx={{ width: '60px' }}
                      type="number"
                      inputProps={{
                        min: 0,
                        style: { textAlign: 'center' }
                      }}
                      error={Number(countOfCargoPlaces) < 0 || countOfCargoPlaces === ''}
                    />
                  </FormControl>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className={style.footerControls}>
          <Button
            disableElevation
            onClick={() => navigate(`/companies/${companyId}/routes`)}
            variant="text"
          >
            Назад
          </Button>
          <Button
            disableElevation
            onClick={save}
            variant="contained"
            disabled={scheduleIsReady}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    )
    : null;
}

VehicleRouteFormWidget.propTypes = {
  updateRoutes: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  isCopy: PropTypes.bool,
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
};

VehicleRouteFormWidget.defaultProps = {
  isCopy: false,
  isEdit: false,
  isCreate: false,
};

export default VehicleRouteFormWidget;
