// eslint-disable-next-line boundaries/element-types
import transformRoute from '../../entities/vehicles/VehiclesRoute/vehiclesRoutes/services/data/transformRoute';
import { parseLine, parsePolygon } from '../geo-map/lib';
import api from './config';
// eslint-disable-next-line import/no-cycle
import {
  deleteApiData, fileUpload, formQuery, getApiData,
  patchApiData, postApiData, putApiData,
} from './lib';

export const getConfig = () => {
  const url = `/api-config`;

  return getApiData(url).then((res) => res.data);
};

export function getToken(body) {
  const url = `${api.baseUrl}/authenticate`;

  return postApiData(url, body).then((res) => res.data.id_token);
}
// метод для получения внутреннего токена по токену VK
export function getTokenVk(token) {
  const url = `${api.baseUrl}/authenticate`;

  return postApiData(url, { token }).then((res) => res.data);
}
// метод для получения токена пользователя админом
export function getImpersonazeToken(userId) {
  const url = `${api.baseUrl}/impersonize?userId=${userId}`;

  return getApiData(url).then((res) => res.data);
}

export function getAccount() {
  const url = `${api.baseUrl}/account`;

  return getApiData(url).then((res) => res.data);
}

export function getAuthorities() {
  const url = `${api.baseUrl}/authorities`;

  return getApiData(url).then((res) => res.data);
}

export const getAppUserAccesses = (options) => {
  const url = `${api.baseUrl}/app-user-accesses${formQuery(options)}`;

  return getApiData(url).then((res) => res.data);
};

export const getCompanies = (options) => {
  const url = `${api.baseUrl}/companies${formQuery(options)}`;

  return getApiData(url).then((res) => (
    res.data.map((company) => ({ ...company, id: company.companyId }))
  ));
};

export const getCompany = (id) => {
  const url = `${api.baseUrl}/companies/${id || ''}`;

  return getApiData(url).then((res) => ({
    ...res.data,
    id: res.data.companyId,
  }));
};

export const getUser = (id) => {
  const url = `${api.baseUrl}/app-users/${id}`;

  return getApiData(url).then((res) => (
    res.data
  ));
};

export const getUsers = (options) => {
  const url = `${api.baseUrl}/users${formQuery(options)}`;

  return getApiData(url).then((res) => (
    res.data
  ));
};

export const getAdminUsers = (option) => {
  const url = `${api.baseUrl}/admin/users/${option || ''}`;

  return getApiData(url).then((res) => (
    res.data
  ));
};

export function getVehicleModels(params) {
  let urlParams = '';
  if (params) {
    urlParams = '?';
    Object.keys(params).forEach((key) => {
      urlParams += `${key}=${params[key]}&`;
    });
  }
  const url = `${api.baseUrl}/vehicle-models${urlParams}`;
  return getApiData(url).then((res) => (
    res.data.map((vehicleModel) => ({ ...vehicleModel, id: vehicleModel.vehicleModelId }))
  ));
}

export const getTrackPoints = (options) => {
  const url = `${api.vstorageUrl}/track-points${formQuery(options)}`;

  return getApiData(url).then((res) => res.data.sort((a, b) => (
    new Date(b.date) - new Date(a.date)
  )));
};

export const getAlerts = (options) => {
  const url = `${api.baseUrl}/alerts${formQuery(options)}`;

  return getApiData(url).then((res) => res.data.map((alert) => ({
    ...alert,
    beginTime: new Date(alert.beginTime),
    endTime: alert.endTime ? new Date(alert.endTime) : null,
    suspendTime: alert.suspendTime ? new Date(alert.suspendTime) : null,
  })));
};

export const getDivisions = (options) => {
  const url = `${api.baseUrl}/divisions${formQuery(options)}`;

  return getApiData(url).then((res) => res.data.map((division) => ({
    ...division,
    id: division.divisionId,
    company: { ...division.company, id: division.company.companyId },
    parent: division.parent ? { ...division.parent, id: division.parent.divisionId } : null,
  })));
};

export const getAlertTypes = (options) => {
  const url = `${api.baseUrl}/alert-types${formQuery(options)}`;

  return getApiData(url).then((res) => res.data);
};

export const getAppUsers = (options) => {
  const url = `${api.baseUrl}/app-users${formQuery(options)}`;

  return getApiData(url).then((res) => res.data.map((user) => ({
    ...user,
    id: user.appUserId,
    name: `${user.firstName} ${user.lastName}`,
  })));
};

export const getAppUsersV2 = (options) => {
  const result = options?.map((option) => `${option.name}=${option.value}`).join('&') || '';

  const url = `${api.baseUrl}/app-users?${result}`;

  return getApiData(url).then((res) => res.data.map((user) => ({
    ...user,
    id: user.appUserId,
    name: `${user.firstName} ${user.lastName}`,
  })));
};

export const getGeoNames = (options) => {
  const url = `${api.baseUrl}/geo-names${formQuery(options)}`;

  return getApiData(url).then((res) => res.data);
};

export const getGeoZones = (options) => {
  const url = `${api.baseUrl}/geo-zones${formQuery(options)}`;

  return getApiData(url).then((res) => res.data
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((geoZone) => ({
      ...geoZone,
      id: geoZone.geozoneId,
      polygon: parsePolygon(geoZone.area)
    })));
};

export const getGeoZonesNoFormQuery = (options) => {
  let result = '?';

  if (options) {
    Object?.keys(options)?.forEach((key) => {
      result += `${key}=${encodeURIComponent(options[key].paramValue || options[key].id || options[key])}&`;
    });
  }

  const url = `${api.baseUrl}/geo-zones${result}`;

  return getApiData(url).then((res) => ({
    total: Number(res.headers['X-Total-Count'] || res.headers['x-total-count']),
    geozones: res.data
      .map((geoZone) => ({
        ...geoZone,
        id: geoZone.geozoneId,
        polygon: parsePolygon(geoZone.area)
      }))
  }));
};

export const getVehicles = (options) => {
  const url = `${api.baseUrl}/vehicles${formQuery(options)}`;

  return getApiData(url).then((res) => res.data.map((vehicle) => ({
    ...vehicle,
    id: vehicle.vehicleId,
    company: { ...vehicle.company, id: vehicle.company.companyId },
    division: { ...vehicle.division, id: vehicle.division.divisionId },
  })));
};

export const getFeadbackCauses = (options) => {
  const url = `${api.baseUrl}/feedback-causes${formQuery(options)}`;
  return getApiData(url).then((res) => res.data.map((item, i) => ({ ...item, id: i })));
};

export const getPlatoonGroupVehicles = (options, params) => {
  let joinedParams = '';
  if (params) {
    Object.keys(params).forEach((key) => {
      params[key].forEach((param) => {
        joinedParams += `${key}=${param}&`;
      });
    });
  }

  const url = `${api.platoonGroupsResourceUrl}/platoon-group-vehicles${formQuery(options)}${joinedParams}`;

  return getApiData(url).then((res) => res.data
    .map((platoonGroupVehicle) => ({
      ...platoonGroupVehicle,
      modifiedDate: platoonGroupVehicle.modifiedDate
        ? new Date(platoonGroupVehicle.modifiedDate)
        : null,
    }))
    .sort((a, b) => b.modifiedDate - a.modifiedDate));
};

export const getVehiclesWithDtoParams = (options) => {
  const url = `${api.baseUrl}/vehicles?${options}`;

  return getApiData(url).then((res) => res.data.map((vehicle) => ({
    ...vehicle,
    id: vehicle.vehicleId,
    company: { ...vehicle.company, id: vehicle.company.companyId },
    division: { ...vehicle.division, id: vehicle.division.divisionId },
  })));
};

export const getVehicleRouteTracks = async (options) => {
  const url = `${api.baseUrl}/vehicle-route-tracks${formQuery(options)}`;
  return getApiData(url).then((res) => res.data.map((vehicleRouteTrack, i) => ({
    id: i,
    ...vehicleRouteTrack,
    track: parseLine(vehicleRouteTrack.track),
  })));
};

export const putVehicleRouteTracks = async (id, body) => {
  const url = `${api.baseUrl}/vehicle-routes/${id}/track`;
  return fileUpload(url, body).then((res) => res.data);
};

export const getPlatoonRequests = (options) => {
  const url = `${api.platoonGroupsResourceUrl}/platoon-requests${formQuery(options)}`;

  return getApiData(url).then((res) => res.data.map((item) => ({
    ...item,
    startDateFrom: item.startDateFrom ? new Date(item.startDateFrom) : null,
    startDateTo: item.startDateTo ? new Date(item.startDateTo) : null,
  })));
};

export const getPlatoonGroupActivities = (params, options = {}) => {
  let optionsUrl = '';
  Object.keys(options).forEach((key) => {
    optionsUrl += `${key}=${options[key]}&`;
  });
  const url = `${api.platoonGroupsResourceUrl}/platoon-group-activities${formQuery(params)}${optionsUrl}`;
  return getApiData(url).then((res) => res.data.map((activity) => ({
    ...activity, modifiedDate: new Date(activity.modifiedDate),
  })));
};

export const getPlatoonGroup = (id) => {
  const url = `${api.platoonGroupsResourceUrl}/platoon-groups/${id || ''}`;
  return getApiData(url).then((res) => res.data);
};

export const getCompanyCargos = (options) => {
  let result = '?';

  Object.keys(options).forEach((key) => {
    result += `${key}=${encodeURIComponent(options[key])}&`;
  });
  const url = `${api.baseUrl}/company-cargos${result}`;
  return getApiData(url).then((res) => ({
    totalCount: Number(res.headers['X-Total-Count'] || res.headers['x-total-count']),
    cargos: res.data.map((cargo) => ({
      ...cargo,
      startDeliveryTime: cargo.startDeliveryTime ? new Date(cargo.startDeliveryTime) : null,
      finishDeliveryTime: cargo.finishDeliveryTime ? new Date(cargo.finishDeliveryTime) : null,
    }))
  }));
};

export const getCompanyCargo = (id) => {
  const url = `${api.baseUrl}/company-cargos/${id}`;
  return getApiData(url).then((res) => ({
    ...res.data,
    startDeliveryTime: res.data.startDeliveryTime ? new Date(res.data.startDeliveryTime) : null,
    finishDeliveryTime: res.data.finishDeliveryTime ? new Date(res.data.finishDeliveryTime) : null,
  }));
};

export const postCompanyCargosImport = (id, file) => {
  const url = `${api.baseUrl}/company-cargos/import?companyId=${id}`;
  return postApiData(url, file).then((res) => (res.data));
};

export const getVehicleRoutePlaces = async (options) => {
  const url = `${api.baseUrl}/vehicle-route-places${formQuery(options)}`;
  return getApiData(url).then((res) => res.data.map((item, i) => ({ ...item, id: i })));
};

export const getVehicleRoutePlacesTrips = (params) => {
  const urlParams = params
    .map((param) => Object.keys(param).map((key) => `${key}=${param[key]}`).join('&'))
    .join('&');
  const url = `${api.baseUrl}/vehicle-route-places/trips?${urlParams}`;
  return getApiData(url).then((res) => res.data.map((item, i) => (
    {
      ...item,
      id: i,
      tickets: item.tickets.map((ticket, ticketIndex) => ({
        id: ticketIndex,
        ...ticket,
        routeStartTime: ticket.routeStartTime ? new Date(ticket.routeStartTime) : null,
        routeFinishTime: ticket.routeFinishTime ? new Date(ticket.routeFinishTime) : null,
      }))
    }
  )));
};

export const getVehicleRouteBatch = (text, cargoType) => {
  const url = `${api.baseUrl}/vehicle-route-places/batch?filter=${encodeURIComponent(text)}&cargoType=${cargoType}`;
  return getApiData(url).then((res) => (res.data));
};

export const getVehicleRoutePoints = (options) => {
  const url = `${api.baseUrl}/vehicle-route-points${formQuery(options)}`;

  return getApiData(url).then((res) => res.data
    .map((p, i) => ({
      ...p,
      id: i,
      arriveTime: p.arriveTime ? new Date(p.arriveTime) : null,
      arriveTimeUntil: p.arriveTimeUntil ? new Date(p.arriveTimeUntil) : null,
      leaveTime: p.leaveTime ? new Date(p.leaveTime) : null,
      leaveTimeUntil: p.leaveTimeUntil ? new Date(p.leaveTimeUntil) : null,
      stopTime: p.stopTime ? new Date(p.stopTime) : null,
      stopTimeUntil: p.stopTimeUntil ? new Date(p.stopTimeUntil) : null,
      backArriveTime: p.backArriveTime ? new Date(p.backArriveTime) : null,
      backArriveTimeUntil: p.backArriveTimeUntil ? new Date(p.backArriveTimeUntil) : null,
      backLeaveTime: p.backLeaveTime ? new Date(p.backLeaveTime) : null,
      backLeaveTimeUntil: p.backLeaveTimeUntil ? new Date(p.backLeaveTimeUntil) : null,
      backStopTime: p.backStopTime ? new Date(p.backStopTime) : null,
      backStopTimeUntil: p.backStopTimeUntil ? new Date(p.backStopTimeUntil) : null,
    }))
    .sort((a, b) => a.orderNum - b.orderNum));
};

export const getVehicleRoutesFindByVehicle = (options) => {
  let result = '?';

  Object.keys(options).forEach((key) => {
    result += `${key}=${encodeURIComponent(options[key])}&`;
  });

  const url = `${api.baseUrl}/vehicle-routes/findByVehicle${result}`;

  return getApiData(url).then((res) => ({
    totalCount: Number(res.headers['X-Total-Count'] || res.headers['x-total-count']),
    routes: res.data.map((vehicleRoute) => ({
      ...vehicleRoute,
      startTime: vehicleRoute.startTime ? new Date(vehicleRoute.startTime) : null,
      startTimeUntil: vehicleRoute.startTimeUntil ? new Date(vehicleRoute.startTimeUntil) : null,
      finishTime: vehicleRoute.finishTime ? new Date(vehicleRoute.finishTime) : null,
      finishTimeUntil: vehicleRoute.finishTimeUntil
        ? new Date(vehicleRoute.finishTimeUntil) : null,
    }))
  }));
};

export const getVehicleRoutesV2 = (options) => {
  const result = options?.map((option) => `${option.name}=${option.value}`).join('&') || '';

  const url = `${api.baseUrl}/vehicle-routes?${result}`;

  return getApiData(url).then((res) => ({
    totalCount: Number(res.headers['X-Total-Count'] || res.headers['x-total-count']),
    routes: res.data.map((vehicleRoute) => transformRoute(vehicleRoute))
  }));
};

export const getVehicleRoute = (id) => {
  const url = `${api.baseUrl}/vehicle-routes/${id}`;

  return getApiData(url).then((res) => transformRoute(res.data));
};

export const getVehicleRoutes = (options) => {
  const url = `${api.baseUrl}/vehicle-routes${formQuery(options)}`;

  return getApiData(url).then((res) => res.data.map((vehicleRoute) => ({
    ...vehicleRoute,
    startTime: vehicleRoute.startTime ? new Date(vehicleRoute.startTime) : null,
    startTimeUntil: vehicleRoute.startTimeUntil ? new Date(vehicleRoute.startTimeUntil) : null,
    finishTime: vehicleRoute.finishTime ? new Date(vehicleRoute.finishTime) : null,
    finishTimeUntil: vehicleRoute.finishTimeUntil ? new Date(vehicleRoute.finishTimeUntil) : null,
  })));
};

export function deleteCompany(option) {
  const url = `${api.baseUrl}/companies/${option || ''}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteDivision(option) {
  const url = `${api.baseUrl}/divisions/${option || ''}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteVehicle(option) {
  const url = `${api.baseUrl}/vehicles/${option || ''}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteVehicleRoutePlace(id) {
  const url = `${api.baseUrl}/vehicle-route-places/${id}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteVehicleRoute(option) {
  const url = `${api.baseUrl}/vehicle-routes/${option || ''}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteGeoZone(option) {
  const url = `${api.baseUrl}/geo-zones/${option || ''}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteVehicleRoutePoint(option) {
  const url = `${api.baseUrl}/vehicle-route-points/${option || ''}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteAppUserAccesses(option) {
  const url = `${api.baseUrl}/app-user-accesses/${option || ''}`;

  return deleteApiData(url).then((res) => res.data);
}

export function deleteAppUser(id) {
  const url = `${api.baseUrl}/app-users/${id}`;

  return deleteApiData(url).then((res) => res.data);
}

export function postVehicleRoute(body) {
  const url = `${api.baseUrl}/vehicle-routes`;
  return postApiData(url, body).then((res) => (res.data));
}

export function postVehicleRouteComplex(body) {
  const url = `${api.baseUrl}/vehicle-routes/complex`;
  return postApiData(url, body).then((res) => (res.data));
}

export function postVehicleRoutePoint(body) {
  const url = `${api.baseUrl}/vehicle-route-points`;

  const newBody = { ...body };
  return postApiData(url, newBody).then((res) => (res.data));
}

export function postAppUserAccesses(body) {
  const url = `${api.baseUrl}/app-user-accesses`;
  return postApiData(url, body).then((res) => (res.data));
}

export const getCompilationPoints = (options, companyId) => {
  const { name, lat, lon } = options;
  const url = `${api.baseUrl}/vehicle-route-points/getCompilationPoints/${name || ''}/${lat || 0}/${lon || 0}?companyId=${companyId}`;
  return getApiData(url).then((res) => res.data);
};

export function postCompany(body) {
  const url = `${api.baseUrl}/companies`;
  return postApiData(url, body).then((res) => ({ ...res.data, id: res.data.companyId }));
}

export function postFeedback(body) {
  const url = `${api.baseUrl}/feedbacks`;
  return postApiData(url, body).then((res) => res.data);
}

export function postGeoZone(body) {
  const url = `${api.baseUrl}/geo-zones`;
  return postApiData(url, body).then((res) => ({
    ...res.data,
    id: res.data.geozoneId,
    polygon: parsePolygon(res.data.area),
  }));
}

export function postDivision(body) {
  const url = `${api.baseUrl}/divisions`;
  return postApiData(url, body).then((res) => ({ ...res.data, id: res.data.divisionId }));
}

export function postVehicle(body) {
  const url = `${api.baseUrl}/vehicles`;
  return postApiData(url, body).then((res) => ({ ...res.data, id: res.data.vehicleId }));
}

export function postAppUser(body) {
  const url = `${api.baseUrl}/app-users`;
  return postApiData(url, body).then((res) => ({
    ...res.data,
    id: res.data.appUserId,
    name: `${res.data.firstName} ${res.data.lastName}`,
  }));
}

export function postUser(body) {
  const url = `${api.baseUrl}/admin/users`;
  return postApiData(url, body).then((res) => ({
    ...res.data,
    name: `${res.data.firstName} ${res.data.lastName}`,
  }));
}

export function postPlatoonRequests(body) {
  const url = `${api.platoonGroupsResourceUrl}/platoon-requests`;
  return postApiData(url, body).then((res) => (res.data));
}

export function postPlatoonGroupActivities(body) {
  const url = `${api.platoonGroupsResourceUrl}/platoon-group-activities`;
  return postApiData(url, body).then((res) => res.data);
}

export function putUsers(body) {
  const url = `${api.baseUrl}/admin/users`;
  return putApiData(url, body).then((res) => ({
    ...res.data,
    name: `${res.data.firstName} ${res.data.lastName}`,
  }));
}

export function putAppUsers(body) {
  const url = `${api.baseUrl}/app-users/${body.appUserId ? body.appUserId : ''}`;
  return putApiData(url, body).then((res) => ({
    ...res.data,
    id: res.data.appUserId,
    name: `${res.data.firstName} ${res.data.lastName}`,
  }));
}

export function patchAppUser(id, body) {
  const url = `${api.baseUrl}/app-users/${id}`;
  return patchApiData(url, body).then((res) => ({
    ...res.data,
    id: res.data.appUserId,
    name: `${res.data.firstName} ${res.data.lastName}`,
  }));
}

export function putVehicle(body) {
  const url = `${api.baseUrl}/vehicles/${body.vehicleId ? body.vehicleId : ''}`;
  return putApiData(url, body).then((res) => ({ ...res.data, id: res.data.vehicleId }));
}

export function putGeoZone(body) {
  const url = `${api.baseUrl}/geo-zones/${body.geozoneId ? body.geozoneId : ''}`;
  return putApiData(url, body).then((res) => ({
    ...res,
    data: {
      ...res.data,
      id: res.data.geozoneId,
      polygon: parsePolygon(res.data.area),
    }
  }));
}

export function putVehicleRoute(body) {
  const url = `${api.baseUrl}/vehicle-routes/${body.vehicleRouteId ? body.vehicleRouteId : ''}`;
  return putApiData(url, body).then((res) => (
    { ...res, data: { ...res.data, id: res.data.vehicleRouteId } }
  ));
}

export function putVehicleRouteComplex(body) {
  const url = `${api.baseUrl}/vehicle-routes/${body.vehicleRouteId ? body.vehicleRouteId : ''}/complex`;
  return putApiData(url, body).then((res) => (
    { ...res, data: { ...res.data, id: res.data.vehicleRouteId } }
  ));
}

export function patchVehicleRoute(body) {
  const url = `${api.baseUrl}/vehicle-routes/${body.vehicleRouteId ? body.vehicleRouteId : ''}`;
  return patchApiData(url, body).then((res) => (
    { ...res, data: { ...res.data, id: res.data.vehicleRouteId } }
  ));
}

export function patchVehicleRouteChangeVehilce(vehicleRouteId, body) {
  const url = `${api.baseUrl}/vehicle-routes/${vehicleRouteId}/changeVehicle`;
  return patchApiData(url, {}, { params: body }).then((res) => (
    { ...res, data: { ...res.data, id: res.data.vehicleRouteId } }
  ));
}

export function putVehicleRouteReeplan(params) {
  const url = `${api.baseUrl}/vehicle-routes/replan`;
  return putApiData(url, {}, { params }).then((res) => (
    { ...res, data: { ...res.data, id: res.data.vehicleRouteId } }
  ));
}

export function putVehicleRoutePoint(body) {
  const url = `${api.baseUrl}/vehicle-route-points/${body.vroutePointId ? body.vroutePointId : ''}`;
  return patchApiData(url, body).then((res) => (
    { ...res, data: { ...res.data, id: res.data.vroutePointId } }
  ));
}

export function putAlert(body) {
  const url = `${api.baseUrl}/alerts/${body.alertId ? body.alertId : ''}`;
  return putApiData(url, body).then((res) => (
    { ...res, data: { ...res.data, id: res.data.alertId } }
  ));
}

export function putDivisions(body) {
  const url = `${api.baseUrl}/divisions/${body.divisionId ? body.divisionId : ''}`;
  return putApiData(url, body).then((res) => ({ ...res.data, id: res.data.divisionId }));
}

export function patchCompany(body) {
  const url = `${api.baseUrl}/companies/${body.companyId ? body.companyId : ''}`;
  return patchApiData(url, body).then((res) => ({ ...res.data, id: res.data.companyId }));
}

export function patchPlatoonGroup(body) {
  const url = `${api.platoonGroupsResourceUrl}/platoon-groups/${body.platoonGroupId ? body.platoonGroupId : ''}`;
  return patchApiData(url, body).then((res) => res.data);
}

export function patchCompanyCargo(id, body) {
  const url = `${api.baseUrl}/company-cargos/${id}`;
  return patchApiData(url, body).then((res) => res.data);
}

export function getCompanyCargosQRcodes(body) {
  const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');
  const params = {
    ...body,
    access_token: token,
  };
  const paramsString = new URLSearchParams(params).toString();
  window.open(`${api.baseUrl}/company-cargos/qrcodes?${paramsString}`, '_blank');
}

export function postReportGenerate(body) {
  const url = `${api.reportsUrl}/reports/generate`;
  const options = {
    responseType: 'blob',
  };
  return postApiData(url, body, options).then((res) => res);
}

export function putCompany(body) {
  const url = `${api.baseUrl}/divisions/${body.companyId ? body.companyId : ''}`;
  return putApiData(url, body).then((res) => ({ ...res.data, id: res.data.companyId }));
}

export function putPlatoonRequests(body) {
  const url = `${api.platoonGroupsResourceUrl}/platoon-requests/${body.platoonReqId ? body.platoonReqId : ''}`;
  return putApiData(url, body).then((res) => res.data);
}

export function putPlatoonGroupVehicles(body) {
  const url = `${api.platoonGroupsResourceUrl}/platoon-group-vehicles/${body.platoonGrpVehicleId ? body.platoonGrpVehicleId : ''}`;
  return putApiData(url, body).then((res) => res.data);
}
