import { toLonLat } from "ol/proj";

function getCoordinatesFormatted(coordinate, isLon) {
  const prefix = isLon ? coordinate > 0 ? 'E' : 'W' : coordinate > 0 ? 'N' : 'S';
  return `${prefix}${Math.round(coordinate * 10000) / 10000}`;
}

function getCoordinatesToLonLat(coordinates) {
  return toLonLat(coordinates).map((coord, i) => (
    getCoordinatesFormatted(coord, i === 0)
  )).reverse().join(' ');
}

export {
  getCoordinatesFormatted,
  getCoordinatesToLonLat,
};
