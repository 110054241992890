import {
  Box, Slider, SliderThumb, Typography, styled
} from '@mui/material';
import * as React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import reducerPath from '../../../app/reducerPath';
import { useAppSelector } from '../../../app/store';
import isValidDate from '../../../shared/utils/isValidDate';

const CustomSlider = styled(Slider)(({ customcolor }) => ({
  height: 2,
  padding: '66px 0 90px',
  margin: '0 0 20px',
  '&:hover': {
    zIndex: 9999999999,
  },
  '& .MuiSlider-thumb': {
    height: 26,
    width: 26,
    backgroundColor: customcolor,
    '& .label': {
      color: 'white',
      fontSize: '0.875rem',
    },
    '& .tooltip': {
      position: 'absolute',
      transformOrigin: 'top left',
      transform: 'rotate(-90deg)',
      fontSize: '0.875rem',
      whiteSpace: 'nowrap',
      left: '3px',
      top: '-4px',
      '&.bottom': {
        transform: 'rotate(-90deg) translateX(-100%)',
        top: '31px',
      },
      '& .order': {
        fontSize: '0.875rem',
        color: 'black',
      }
    },
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
      zIndex: 9999999999,
    },
  },
  '& .MuiSlider-track': {
    height: 2,
  },
  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    height: 2,
  },
}));

function ThumbComponent(props) {
  const {
    children, ownerState, 'data-index': dataIndex, ...other
  } = props;
  const item = ownerState.marks[dataIndex];
  return (
    !!item
    && (
    <SliderThumb {...other} ownerState={ownerState} sx={{ filter: item.isInactive ? 'contrast(0.176) brightness(1.7)' : 'none' }}>
      {children}
      <Typography className="label">{item.orderLabel}</Typography>
      <Typography className={`tooltip ${item.isLoading ? 'top' : 'bottom'}`} sx={{ color: 'text.secondary' }}>
        {!item.isLoading ? item.date : null}
        {' '}
        <Typography className="order" component="span">{item.orderIdShort}</Typography>
        {' '}
        {item.isLoading ? item.date : null}
      </Typography>
    </SliderThumb>
    )
  );
}

ThumbComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  ownerState: PropTypes.shape({
    marks: PropTypes.arrayOf(PropTypes.shape({
      isInactive: PropTypes.bool,
      isLoading: PropTypes.bool,
      orderNum: PropTypes.number,
      orderLabel: PropTypes.number,
      orderNumLoading: PropTypes.number,
      orderNumDelivery: PropTypes.number,
      date: PropTypes.string,
      orderIdShort: PropTypes.string,
    })),
  }).isRequired,
  'data-index': PropTypes.number.isRequired,
};

const findRespondingThumbInd = (actIndex, thumbArr) => {
  const actThumb = thumbArr[actIndex];
  const pairIndex = thumbArr.findIndex((t) => (t.orderId === actThumb.orderId
    && t.orderNum !== actThumb.orderNum));
  if ((pairIndex < actIndex && actThumb.isLoading)
    || (pairIndex > actIndex && !actThumb.isLoading)) {
    return pairIndex;
  }
  return null;
};

export default function DeliveryReorderingComponent({
  vehicle, color, reorderLogisticTask, orderPoints,
}) {
  const logisticTask = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);

  const [marks, setMarks] = React.useState([]);
  const [activeThumb, setActiveThumb] = React.useState();
  const [prevActiveThumb, setPrevActiveThumb] = React.useState();
  const [rawValues, setRawValues] = React.useState([]);
  const [oldMarks, setOldMarks] = React.useState([]);

  // Дополнительные 0.01, чтобы можно было затащить любой маркер в крайнюю правую позицию
  // MUI-слайдер позволяет перетащить маркер только в занятую другим маркером крайнюю левую позицию
  const sliderSize = React.useMemo(() => (
    vehicle?.route?.orders.length || 0
  ) * 2 + 0.01, [vehicle]);

  const selectedOrders = React.useMemo(() => logisticTask?.selectedOrders, [logisticTask]);

  React.useEffect(() => {
    const marksNew = marks?.length ? [...marks] : [];
    const activeThumbData = marksNew[activeThumb];
    if (prevActiveThumb !== undefined) {
      if (activeThumb !== undefined) {
        const prevThumbData = marksNew.splice(prevActiveThumb, 1)[0];
        if (prevThumbData.orderId !== activeThumbData.orderId) {
          marksNew.splice(activeThumb, 0, prevThumbData);
          // Проверяем, что не перескочили через связанную точку (при быстром драге)
          // и возвращаем точки в правильный порядок при необходимости
          const pairToReplace = findRespondingThumbInd(activeThumb, marksNew);
          if (pairToReplace !== null) {
            const newActiveThumbData = marksNew[activeThumb];
            marksNew[activeThumb] = marksNew[pairToReplace];
            marksNew[pairToReplace] = newActiveThumbData;
          }
          setMarks(marksNew.map((m) => ({
            ...m, isInactive: m.orderId !== prevThumbData.orderId
          })));
        }
      }
    } else {
      setMarks(marksNew.map((m) => ({
        ...m,
        isInactive: activeThumbData ? m.orderId !== activeThumbData.orderId
          : selectedOrders?.length
            ? !selectedOrders?.includes(m.orderId) : false
      })));
    }
    setRawValues(rawValues.map((r, i) => (i !== activeThumb ? i + 1 : activeThumb ? r : i + 1)));

    setPrevActiveThumb(activeThumb);
  }, [activeThumb]);

  React.useEffect(() => {
    const startDelta = (vehicle?.startLat || vehicle?.startLat === 0) ? 1 : 0;
    const marksValue = vehicle?.route?.orders?.reduce((prev, current) => {
      let loadingTime = parseISO(current.loadingTime);
      loadingTime = isValidDate(loadingTime) ? loadingTime : new Date();
      let deliveryTime = parseISO(current.deliveryTime);
      deliveryTime = isValidDate(deliveryTime) ? deliveryTime : new Date();
      return [
        ...prev,
        {
          dateValue: loadingTime,
          date: format(loadingTime, 'HH:mm'),
          value: prev.length + 1,
          isLoading: true,
          orderId: current.orderSource?.id,
          orderNum: current.orderNumLoading ? current.orderNumLoading + startDelta : 0,
          orderLabel: orderPoints[current.orderSource?.id].loading.index,
          orderIdShort: String(current.orderSource?.id).substr(-4),
          isInactive: selectedOrders?.length
            ? !selectedOrders?.includes(current.orderSource?.id) : false,
        },
        {
          dateValue: deliveryTime,
          date: format(deliveryTime, 'HH:mm'),
          value: prev.length + 2,
          isLoading: false,
          orderId: current.orderSource?.id,
          orderNum: current.orderNumDelivery ? current.orderNumDelivery + startDelta : 0,
          orderLabel: orderPoints[current.orderSource?.id].unloading.index,
          orderIdShort: String(current.orderSource?.id).substr(-4),
          isInactive: selectedOrders?.length
            ? !selectedOrders?.includes(current.orderSource?.id) : false,
        },
      ];
    }, [])
      .sort(({ dateValue: dateA }, { dateValue: dateB }) => dateA - dateB)
      .map((m, i) => ({
        ...m,
        orderNum: m.orderNum !== 0 ? m.orderNum : i + startDelta + 1,
      }));
    setOldMarks(marksValue?.length ? [...marksValue] : []);
    setMarks(marksValue);
    setRawValues(marksValue?.map(({ value }) => value));
  }, [vehicle]);

  return (
    <>
      <Typography variant="title" sx={{ mx: 2 }}>{vehicle.registrationNumber}</Typography>
      <Box
        sx={{
          px: { sm: 4 },
          py: { sm: 2 },
          overflowY: 'hidden',
          overflowX: 'auto',
        }}
      >
        <CustomSlider
          sx={{
            minWidth: ((marks?.length || 0) - 1) * 40,
          }}
          customcolor={color}
          disableSwap={false}
          track={false}
          value={rawValues || []}
          marks={marks || []}
          max={sliderSize}
          min={1}
          slots={{ thumb: ThumbComponent }}
          step={0.1}
          onChange={(e, i, t) => {
            setActiveThumb(t);
            setRawValues(e.target.value);
          }}
          onChangeCommitted={() => {
            setPrevActiveThumb();
            setActiveThumb();
            const hasDiffs = marks.some((m, i) => m.value !== oldMarks[i].value);
            if (!hasDiffs) return;
            const newData = JSON.parse(JSON.stringify(logisticTask.data));
            const route = newData.solution.routes.find((r) => (
              r.vehicleDetails.registrationNumber === vehicle.id
            ));
            marks.forEach((m, i) => {
              const order = route.orders.find((o) => m.orderId === o.orderSource?.id);
              if (m.isLoading) {
                order.orderNumLoading = i + 1;
              } else {
                order.orderNumDelivery = i + 1;
              }
            });
            reorderLogisticTask({
              body: {
                ...newData,
                solution: {
                  ...newData.solution, routes: [route]
                }
              }
            });
          }}
        />
      </Box>
    </>
  );
}

DeliveryReorderingComponent.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    registrationNumber: PropTypes.string,
    startLat: PropTypes.number,
    route: PropTypes.shape({
      orders: PropTypes.arrayOf(PropTypes.shape({
        loadingTime: PropTypes.string,
        deliveryTime: PropTypes.string,
      })),
    })
  }),
  color: PropTypes.string,
  reorderLogisticTask: PropTypes.func.isRequired,
  orderPoints: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      loading: PropTypes.shape({
        index: PropTypes.number,
      }),
      unloading: PropTypes.shape({
        index: PropTypes.number,
      }),
    }),
  }),
};

DeliveryReorderingComponent.defaultProps = {
  vehicle: null,
  color: 'gray',
  orderPoints: {},
};
