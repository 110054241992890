import {
  Box, Button,
  FormControl, FormControlLabel, FormHelperText,
  InputLabel,
  MenuItem,
  Paper, Radio,
  Select, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import style from "./ScheduleWidget.module.css";
import ActionBarDateTimePicker from "../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker";

export const daysOfWeek = [
  { id: "MON", value: 'Пн' },
  { id: "TUE", value: 'Вт' },
  { id: "WEN", value: 'Ср' },
  { id: "THU", value: 'Чт' },
  { id: "FRI", value: 'Пт' },
  { id: "SAT", value: 'Сб' },
  { id: "SUN", value: 'Вс' }
];

const daysOfWeekPlusMore = [
  ...daysOfWeek,
  { id: "DAY_OF_MONTH", value: 'День месяца' }
];

export const months = [
  { id: "JANUARY", value: 'Январь', short: 'Янв' },
  { id: "FEBRUARY", value: 'Февраль', short: 'Фев' },
  { id: "MARCH", value: 'Март', short: 'Мар' },
  { id: "APRIL", value: 'Апрель', short: 'Апр' },
  { id: "MAY", value: 'Май', short: 'Май' },
  { id: "JUNE", value: 'Июнь', short: 'Июнь' },
  { id: "JULY", value: 'Июль', short: 'Июль' },
  { id: "AUGUST", value: 'Август', short: 'Авг' },
  { id: "SEPTEMBER", value: 'Сентябрь', short: 'Сен' },
  { id: "OCTOBER", value: 'Октябрь', short: 'Окт' },
  { id: "NOVEMBER", value: 'Ноябрь', short: 'Ноя' },
  { id: "DECEMBER", value: 'Декабрь', short: 'Дек' },
];

const dateNow = new Date();
const yearNow = new Date(dateNow).getFullYear();
const monthNow = dateNow.getMonth();
// Запрещено менять последовательность retryArr
const retryArr = [{ id: "EVERYDAY", name: "Ежедневно" }, { id: "EVERYWEEK", name: "Еженедельно" }, { id: "EVERYMONTH", name: "Ежемесячно" }, { id: "EVERYYEAR", name: "Ежегодно" }, { id: "NORETRY", name: "Не повторять" }];
// Запрещено менять последовательность stopRetryArrChoice
const stopRetryArrChoice = [{ id: "RETRIES", name: "После" }, { id: "UNTIL", name: "В дату" }, { id: 0, name: "Никогда" }];
// Запрещено менять последовательность stopRetryArrChoice
export const moreFunctions = [
  {
    id: "1TH", name: "Первый", short: '1-й', shortFm: '1-я', shortMd: '1-е'
  },
  {
    id: "2TH", name: "Второй", short: '2-й', shortFm: '2-я', shortMd: '2-е'
  },
  {
    id: "3TH", name: "Третий", short: '3-й', shortFm: '3-я', shortMd: '3-е'
  },
  {
    id: "4TH", name: "Четвертый", short: '4-й', shortFm: '4-я', shortMd: '4-е'
  },
  {
    id: "5TH", name: "Пятый", short: '5-й', shortFm: '5-я', shortMd: '5-е'
  },
  {
    id: "LAST", name: "Последний", short: 'Последн', shortFm: 'Последн', shortMd: 'Последн'
  },
];

export function extractValueFromString(input, key) {
  const regex = new RegExp(`\\b${key}=([^;]+)`);
  const match = input.match(regex);
  return match ? match[1] : null;
}

const createMonthCalendar = (currentDate) => {
  const currentMonth = currentDate.getMonth();
  const currentYear = new Date(currentDate).getFullYear();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const firstDayOfWeek = new Date(currentYear, currentMonth, 1).getDay();

  // Дни недели, относящиеся к соседним месяцам
  const blankCells = new Array((firstDayOfWeek + 6) % 7).fill({ day: null });

  const daysOfMonthsCells = Array.from(Array(daysInMonth)).map((_, index) => ({
    day: index + 1,
  }));

  const allCells = [...blankCells, ...daysOfMonthsCells];

  const countRows = Math.ceil(allCells.length / 7);
  // Разделение всех ячеек построчно
  return Array.from(Array(countRows)).map((_, index) => ({
    rowId: index,
    cells: allCells.slice(index * 7, (index * 7) + 7).map((cell, i) => ({ ...cell, cellId: i }))
  }));
};

const retryArrChoice = (idChoice, setSelectedItemRetryStore, selectedItemRetryStore) => {
  switch (idChoice) {
    case retryArr[0].id: {
      return (
        <RetryEveryDay
          setSelectedItemRetryStore={setSelectedItemRetryStore}
          selectedItemRetryStore={selectedItemRetryStore}
        />
      );
    }
    case retryArr[1].id: {
      return (
        <RetryEveryWeek
          setSelectedItemRetryStore={setSelectedItemRetryStore}
          selectedItemRetryStore={selectedItemRetryStore}
        />
      );
    }
    case retryArr[2].id: {
      return (
        <RetryEveryMonth
          setSelectedItemRetryStore={setSelectedItemRetryStore}
          selectedItemRetryStore={selectedItemRetryStore}
        />
      );
    }
    case retryArr[3].id: {
      return (
        <RetryEveryYear
          setSelectedItemRetryStore={setSelectedItemRetryStore}
          selectedItemRetryStore={selectedItemRetryStore}
        />
      );
    }
    default:
      return "";
  }
};

function ScheduleWidget({ scheduleValue, onChangeSсhedule }) {
  const [selectedItemRetryStore, setSelectedItemRetryStore] = useState(scheduleValue);
  const def = useMemo(() => extractValueFromString(selectedItemRetryStore, "TYPE"), [selectedItemRetryStore]);
  const [selectedItemRetry, setSelectedItemRetry] = useState();
  const defORDER = useMemo(() => extractValueFromString(selectedItemRetryStore, "ORDER"), [selectedItemRetryStore]);
  const defDAYS = useMemo(() => extractValueFromString(selectedItemRetryStore, "DAYS"), [selectedItemRetryStore]);

  useEffect(() => {
    setSelectedItemRetry(def);
  }, [def]);

  useEffect(() => {
    setSelectedItemRetryStore(scheduleValue);
  }, [scheduleValue]);

  const handleChange = (event) => {
    setSelectedItemRetry(event.target.value);
    if (event) {
      setSelectedItemRetryStore(`TYPE=${event.target.value};EACH_N=1;`);
    } else {
      setSelectedItemRetryStore('');
    }
  };

  useEffect(() => {
    const availableSave = (def === retryArr[2].id || def === retryArr[3].id)
      ? (defORDER ? !defDAYS : false) || (defDAYS ? !defORDER : false)
      : false;
    onChangeSсhedule(selectedItemRetryStore, availableSave);
  }, [selectedItemRetryStore, def]);

  return (
    <Box className={style.companyPaymentsPanel}>
      <Box className={style.constrols}>
        <FormControl
          size="small"
          sx={{ minWidth: 120 }}
        >
          <InputLabel>Повторять</InputLabel>
          <Select
            label="Повторять"
            value={selectedItemRetry || retryArr[4].id}
            onChange={handleChange}
          >
            {retryArr.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <FormControl
        size="small"
        sx={{ minWidth: 120 }}
      >
        {retryArrChoice(selectedItemRetry, setSelectedItemRetryStore, selectedItemRetryStore)}
      </FormControl>
      <Box sx={{
        width: "max-content",
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        gap: "10px"
      }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedItemRetryStore("");
            setTimeout(() => { setSelectedItemRetryStore(scheduleValue); }, 0);
          }}
        >
          Очистить
        </Button>
      </Box>
    </Box>
  );
}

ScheduleWidget.propTypes = {
  onChangeSсhedule: PropTypes.func.isRequired,
  scheduleValue: PropTypes.string.isRequired,
};

function replaceValue(str, newValue, el) {
  // Разбиваем строку по точкам с запятыми
  const parts = str.split(';');

  // Находим индекс строки, содержащей "EACH_N"
  const index = parts.findIndex((part) => part.includes(el));

  // Если находим строку с "EACH_N", заменяем значение
  if (index !== -1) {
    // Разбиваем строку с "EACH_N" по знаку равенства
    const keyValue = parts[index].split('=');

    // Заменяем значение у "EACH_N"
    keyValue[1] = newValue.toString();

    // Обновляем строку в массиве
    parts[index] = keyValue.join('=');
  }

  // Собираем все части обратно в строку
  return parts.join(';');
}

function RetryEveryDay({ setSelectedItemRetryStore, selectedItemRetryStore }) {
  const defRETRIES = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);
  const defUNTIL = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);
  const def = defRETRIES ? stopRetryArrChoice[0].id : defUNTIL ? stopRetryArrChoice[1].id : null;
  const [stopRetry, setStopRetry] = useState(def || stopRetryArrChoice[2].id);
  const defRETRIESCount = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);

  const [retryCount, setRetryCount] = useState(defRETRIESCount);
  const defUNTILData = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);

  const [retryData, setRetryData] = useState(defUNTILData);
  const defEachN = extractValueFromString(selectedItemRetryStore, "EACH_N");
  const [every, setEvery] = useState(defEachN);

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        Каждые
        <Box sx={{ width: "152px" }}>
          <TextField
            InputProps={{ inputProps: { min: 0 } }}
            size="small"
            value={every || ""}
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                const newValue = e.target.value.replaceAll("-", "").replaceAll("e", "");

                const newString = selectedItemRetryStore.includes("EACH_N")
                  ? replaceValue(selectedItemRetryStore, newValue, 'EACH_N')
                  : `${selectedItemRetryStore}EACH_N=${newValue}`;
                setEvery(newValue);
                setSelectedItemRetryStore(
                  newString
                );
              } else {
                setEvery(null);
              }
            }}
          />
        </Box>
        дней
      </Box>
      <Box sx={{
        display: "flex", gap: "10px", alignItems: "center", paddingTop: "20px"
      }}
      >
        <FormControl
          size="small"
          sx={{ minWidth: 120, width: "280px" }}
        >
          <InputLabel>Прекратить повторение</InputLabel>
          <Select
            value={stopRetry}
            onChange={(event) => {
              setStopRetry(event.target.value);
              if (!event.target.value) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[0].id}${every ? `;EACH_N=${every}` : ""};`
                );
              }
            }}
            label="Прекратить повторение"
          >
            {stopRetryArrChoice.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {stopRetry === stopRetryArrChoice[1].id ? (
          <DatePicker
            label="Дата"
            value={retryData}
            onChange={(e) => {
              setRetryData(e);
              // eslint-disable-next-line no-restricted-globals
              if (e && !isNaN(e)) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[0].id}${every ? `;EACH_N=${every}` : ""};${`${stopRetryArrChoice[1].id}=${e.toISOString()};`}`

                );
              }
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
              />
            )}
            components={{
              ActionBar: ActionBarDateTimePicker,
            }}
          />
        ) : stopRetry === stopRetryArrChoice[0].id ? (
          <TextField
            label="Раз"
            size="small"
            value={retryCount || ""}
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                setRetryCount(e.target.value);
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[0].id}${every ? `;EACH_N=${every}` : ""};${`${stopRetryArrChoice[0].id}=${e.target.value}`}`

                );
              }
            }}
          />
        ) : null }
      </Box>
    </>
  );
}

RetryEveryDay.propTypes = {
  setSelectedItemRetryStore: PropTypes.func.isRequired,
  selectedItemRetryStore: PropTypes.string.isRequired,
};

function RetryEveryWeek({ setSelectedItemRetryStore, selectedItemRetryStore }) {
  const defRETRIES = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);
  const defUNTIL = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);
  const def = defRETRIES ? stopRetryArrChoice[0].id : defUNTIL ? stopRetryArrChoice[1].id : null;
  const [stopRetry, setStopRetry] = useState(def || stopRetryArrChoice[2].id);
  const defRETRIESCount = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);
  const defWeekDays = extractValueFromString(selectedItemRetryStore, "DAYS");
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(defWeekDays?.length ? defWeekDays?.split(",") : []);
  const [retryCount, setRetryCount] = useState(defRETRIESCount);
  const defUNTILData = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);

  const [retryData, setRetryData] = useState(defUNTILData);
  const defEachN = extractValueFromString(selectedItemRetryStore, "EACH_N");
  const [every, setEvery] = useState(defEachN);

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        Каждые
        <Box sx={{ width: "152px" }}>
          <TextField
            value={every || ""}
            size="small"
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                const newString = selectedItemRetryStore.includes("EACH_N")
                  ? replaceValue(selectedItemRetryStore, e.target.value, 'EACH_N')
                  : `${selectedItemRetryStore}EACH_N=${e.target.value};`;
                setEvery(e.target.value);
                setSelectedItemRetryStore(
                  newString
                );
              }
            }}
          />
        </Box>
        недель(и)
      </Box>
      <Box sx={{
        display: "flex", gap: "10px", alignItems: "center", paddingTop: "20px"
      }}
      >
        <TableContainer component={Paper}>
          <Table className={style.table} size="small">
            <TableHead>
              <TableRow
                className={style.tableHead}
              >
                {daysOfWeek.map((dayOfWeek) => (
                  <TableCell
                    key={dayOfWeek.id}
                    className={style.cellTableHead}
                    align="center"
                    style={{ background: selectedDayOfWeek.includes(dayOfWeek.id) ? "#1876d2" : null, cursor: "pointer" }}
                    onClick={() => {
                      setSelectedDayOfWeek(selectedDayOfWeek.includes(dayOfWeek.id)
                        ? (prev) => {
                          const newValue = prev.filter((e) => e !== dayOfWeek.id);
                          const newString = selectedItemRetryStore.includes("DAYS")
                            ? replaceValue(selectedItemRetryStore, newValue.join(), 'DAYS')
                            : `${selectedItemRetryStore}DAYS=${newValue.join()};`;
                          setSelectedItemRetryStore(newString);

                          return newValue;
                        }
                        : (prev) => {
                          const newValue = [...prev, dayOfWeek.id];
                          const newString = selectedItemRetryStore.includes("DAYS")
                            ? replaceValue(selectedItemRetryStore, newValue.join(), 'DAYS')
                            : `${selectedItemRetryStore}DAYS=${newValue.join()};`;
                          setSelectedItemRetryStore(newString);

                          return newValue;
                        });
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: 'text.secondary',
                        fontSize: "16px",
                        fontWeight: "400"
                      }}
                    >
                      {dayOfWeek.value}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody />
          </Table>
        </TableContainer>

      </Box>
      <Box sx={{
        display: "flex", gap: "10px", alignItems: "center", paddingTop: "20px"
      }}
      >
        <FormControl
          size="small"
          sx={{ minWidth: 120, width: "280px" }}
        >
          <InputLabel>Прекратить повторение</InputLabel>
          <Select
            value={stopRetry}
            onChange={(event) => {
              setStopRetry(event.target.value);
              if (!event.target.value) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[1].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;DAYS=${selectedDayOfWeek.join()}` : ""};`

                );
              }
            }}
            label="Прекратить повторение"
          >
            {stopRetryArrChoice.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {stopRetry === stopRetryArrChoice[1].id ? (
          <DatePicker
            label="Дата"
            value={retryData}
            onChange={(e) => {
              setRetryData(e);
              // eslint-disable-next-line no-restricted-globals
              if (e && !isNaN(e)) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[1].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;DAYS=${selectedDayOfWeek.join()}` : ""};${`${stopRetryArrChoice[1].id}=${e.toISOString()};`}`

                );
              }
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
              />
            )}
            components={{
              ActionBar: ActionBarDateTimePicker,
            }}
          />
        ) : stopRetry === stopRetryArrChoice[0].id ? (
          <TextField
            label="Раз"
            size="small"
            value={retryCount || ""}
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                setRetryCount(e.target.value);
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[1].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;DAYS=${selectedDayOfWeek.join()}` : ""};${`${stopRetryArrChoice[0].id}=${e.target.value}`}`
                );
              }
            }}
          />
        ) : null }

      </Box>
    </>
  );
}

RetryEveryWeek.propTypes = {
  setSelectedItemRetryStore: PropTypes.func.isRequired,
  selectedItemRetryStore: PropTypes.string.isRequired,
};

function RetryEveryMonth({ setSelectedItemRetryStore, selectedItemRetryStore }) {
  const defRETRIES = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);
  const defUNTIL = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);
  const def = defRETRIES ? stopRetryArrChoice[0].id : defUNTIL ? stopRetryArrChoice[1].id : null;
  const [stopRetry, setStopRetry] = useState(def || stopRetryArrChoice[2].id);
  const defRETRIESCount = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);
  const [retryCount, setRetryCount] = useState(defRETRIESCount);
  const defUNTILData = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);
  const defMonthDays = extractValueFromString(selectedItemRetryStore, "MONTH_DAYS");
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(defMonthDays?.length ? defMonthDays?.split(",")?.map((e) => Number(e)) : []);
  const [cellsCalendar] = useState(
    createMonthCalendar(new Date(yearNow, monthNow))
  );
  const [retryData, setRetryData] = useState(defUNTILData);
  const defEachN = extractValueFromString(selectedItemRetryStore, "EACH_N");
  const [every, setEvery] = useState(defEachN);

  const defEveryDay = extractValueFromString(selectedItemRetryStore, "ORDER");
  const [everyDay, setEveryDay] = useState(defEveryDay);

  const defEveryDayDayOfWeek = extractValueFromString(selectedItemRetryStore, "DAYS");
  const [everyDayDayOfWeek, setEveryDayDayOfWeek] = useState(defEveryDayDayOfWeek);

  const [switchEveryFunc, setSwitchEveryFunc] = useState(!(defEveryDay || defEveryDayDayOfWeek));

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        Каждые
        <Box sx={{ width: "152px" }}>
          <TextField
            size="small"
            value={every || ""}
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                const newString = selectedItemRetryStore.includes("EACH_N")
                  ? replaceValue(selectedItemRetryStore, e.target.value, 'EACH_N')
                  : `${selectedItemRetryStore}EACH_N=${e.target.value};`;
                setEvery(e.target.value);
                setSelectedItemRetryStore(newString);
              }
            }}
          />
        </Box>
        месяца(цев)
      </Box>

      <FormControlLabel
        onClick={() => {
          setSelectedDayOfMonth([]);
          setEveryDayDayOfWeek(null);
          setEveryDay(null);
          setSelectedItemRetryStore(
            `TYPE=${retryArr[2].id}${every ? `;EACH_N=${every}` : ""}${retryCount ? `;${stopRetryArrChoice[0].id}=${retryCount}` : ""}${retryData ? `;${stopRetryArrChoice[1].id}=${new Date(retryData).toISOString()}` : ""};`

          );
          setSwitchEveryFunc(() => true);
        }}
        value=""
        control={<Radio checked={switchEveryFunc} />}
        label=""
      />

      <Box sx={{
        opacity: switchEveryFunc ? 1 : 0.3,
        pointerEvents: switchEveryFunc ? "auto" : "none",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        paddingTop: "20px"
      }}
      >
        <TableContainer component={Paper}>
          <Table className={style.table} size="small">
            <TableHead>
              <TableRow
                className={style.tableHead}
              >
                {daysOfWeek.map((dayOfWeek) => (
                  <TableCell
                    key={dayOfWeek.id}
                    className={style.cellTableHead}
                    align="center"
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: 'text.secondary',
                        fontSize: "16px",
                        fontWeight: "400"
                      }}
                    >
                      {dayOfWeek.value}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cellsCalendar.map((row) => (
                <TableRow key={row.rowId}>
                  {row.cells.map((cell) => (
                    <TableCell
                      style={{ background: selectedDayOfMonth?.includes(cell.day) ? "#1876d2" : null, cursor: "pointer" }}
                      onClick={() => {
                        if (cell.day !== null) {
                          setSelectedDayOfMonth(selectedDayOfMonth.includes(cell.day)
                            ? (prev) => {
                              const newValue = prev.filter((e) => e !== cell.day);

                              const newString = selectedItemRetryStore.includes("MONTH_DAYS")
                                ? replaceValue(selectedItemRetryStore, newValue.join(), 'MONTH_DAYS')
                                : `${selectedItemRetryStore}MONTH_DAYS=${newValue.join()};`;
                              setSelectedItemRetryStore(newString);
                              return newValue;
                            }
                            : (prev) => {
                              const newValue = [...prev, cell.day];
                              const newString = selectedItemRetryStore.includes("MONTH_DAYS")
                                ? replaceValue(selectedItemRetryStore, newValue.join(), 'MONTH_DAYS')
                                : `${selectedItemRetryStore}MONTH_DAYS=${newValue.join()};`;
                              setSelectedItemRetryStore(newString);
                              return newValue;
                            });
                        }
                      }}
                      key={cell.cellId}
                      className={style.cellTable}
                    >
                      <Box className={style.cellBox}>
                        <Box className={style.payments} />
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: 'text.secondary',
                            fontSize: "16px",
                            fontWeight: "400"
                          }}
                        >
                          {cell.day || ''}
                        </Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <FormControlLabel
        onClick={() => {
          setSelectedDayOfMonth([]);
          setEveryDayDayOfWeek(null);
          setEveryDay(null);
          setSelectedItemRetryStore(
            `TYPE=${retryArr[2].id}${every ? `;EACH_N=${every}` : ""}${retryCount ? `;${stopRetryArrChoice[0].id}=${retryCount}` : ""}${retryData ? `;${stopRetryArrChoice[1].id}=${new Date(retryData).toISOString()}` : ""};`

          );
          setSwitchEveryFunc(() => false);
        }}
        value=""
        control={<Radio checked={!switchEveryFunc} />}
        label=""
      />
      <Box
        sx={{
          opacity: !switchEveryFunc ? 1 : 0.3,
          pointerEvents: !switchEveryFunc ? "auto" : "none",
          display: "flex",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        <FormControl
          size="small"
          sx={{ minWidth: 120, width: "280px" }}
          error={!everyDay}
        >
          <InputLabel id="every-simple-select-label">Каждый</InputLabel>
          <Select
            labelId="every-simple-select-label"
            id="every-simple-select"
            value={everyDay}
            error={everyDay ? null : "Ошибка"}
            onChange={(event) => {
              setEveryDay(event.target.value);
              if (event.target.value) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[2].id}${every ? `;EACH_N=${every}` : ""}${retryCount ? `;${stopRetryArrChoice[0].id}=${retryCount}` : ""}${retryData ? `;${stopRetryArrChoice[1].id}=${new Date(retryData).toISOString()}` : ""}${event.target.value ? `;ORDER=${event.target.value}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""};`
                );
              }
            }}
            label="Каждый"
          >
            {moreFunctions.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {everyDay ? null : (
            <FormHelperText sx={{
              position: "absolute",
              top: "35px",
              left: "-12px"
            }}
            >
              Обязательное поле для запаолнения
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          size="small"
          sx={{ minWidth: 120, width: "280px" }}
          error={!everyDayDayOfWeek}
        >
          <InputLabel>День недели</InputLabel>
          <Select
            value={everyDayDayOfWeek}
            error={everyDayDayOfWeek ? null : "Ошибка"}
            onChange={(event) => {
              setEveryDayDayOfWeek(event.target.value);
              if (event.target.value) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[2].id}${every ? `;EACH_N=${every}` : ""}${retryCount ? `;${stopRetryArrChoice[0].id}=${retryCount}` : ""}${retryData ? `;${stopRetryArrChoice[1].id}=${new Date(retryData).toISOString()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${event.target.value ? `;DAYS=${event.target.value}` : ""};`

                );
              }
            }}
            label="День недели"
          >
            {daysOfWeekPlusMore.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
          {everyDayDayOfWeek ? null : (
            <FormHelperText sx={{
              position: "absolute",
              top: "35px",
              left: "-12px"
            }}
            >
              Обязательное поле для запаолнения
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box sx={{
        display: "flex", gap: "10px", alignItems: "center", paddingTop: "20px"
      }}
      >
        <FormControl
          size="small"
          sx={{ minWidth: 120, width: "280px" }}
        >
          <InputLabel>Прекратить повторение</InputLabel>
          <Select
            value={stopRetry}
            onChange={(event) => {
              setStopRetry(event.target.value);
              if (!event.target.value) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[2].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfMonth?.length ? `;MONTH_DAYS=${selectedDayOfMonth.join()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""};`
                );
              }
            }}
            label="Прекратить повторение"
          >
            {stopRetryArrChoice.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {stopRetry === stopRetryArrChoice[1].id ? (
          <DatePicker
            label="Дата"
            value={retryData}
            onChange={(e) => {
              setRetryData(e);
              // eslint-disable-next-line no-restricted-globals
              if (e && !isNaN(e)) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[2].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfMonth?.length ? `;MONTH_DAYS=${selectedDayOfMonth.join()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""};${`${stopRetryArrChoice[1].id}=${e.toISOString()};`}`
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
              />
            )}
            components={{
              ActionBar: ActionBarDateTimePicker,
            }}
          />
        ) : stopRetry === stopRetryArrChoice[0].id ? (
          <TextField
            label="Раз"
            size="small"
            value={retryCount || ""}
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                setRetryCount(e.target.value);
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[2].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfMonth?.length ? `;MONTH_DAYS=${selectedDayOfMonth.join()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""};${`${stopRetryArrChoice[0].id}=${e.target.value}`};`
                );
              }
            }}
          />
        ) : null }

      </Box>
    </>
  );
}

RetryEveryMonth.propTypes = {
  setSelectedItemRetryStore: PropTypes.func.isRequired,
  selectedItemRetryStore: PropTypes.string.isRequired,
};

function RetryEveryYear({ setSelectedItemRetryStore, selectedItemRetryStore }) {
  const defRETRIES = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);
  const defUNTIL = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);
  const def = defRETRIES ? stopRetryArrChoice[0].id : defUNTIL ? stopRetryArrChoice[1].id : null;
  const [stopRetry, setStopRetry] = useState(def || stopRetryArrChoice[2].id);
  const defRETRIESCount = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[0].id);
  const defWeekDays = extractValueFromString(selectedItemRetryStore, "MONTH");
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(defWeekDays?.length ? defWeekDays?.split(",") : []);
  const [retryCount, setRetryCount] = useState(defRETRIESCount);
  const defUNTILData = extractValueFromString(selectedItemRetryStore, stopRetryArrChoice[1].id);

  const [retryData, setRetryData] = useState(defUNTILData);
  const defEachN = extractValueFromString(selectedItemRetryStore, "EACH_N");
  const [every, setEvery] = useState(defEachN);
  const defEveryDay = extractValueFromString(selectedItemRetryStore, "ORDER");
  const [everyDay, setEveryDay] = useState(defEveryDay);

  const defEveryDayDayOfWeek = extractValueFromString(selectedItemRetryStore, "DAYS");
  const [everyDayDayOfWeek, setEveryDayDayOfWeek] = useState(defEveryDayDayOfWeek);

  const [switchEveryFunc, setSwitchEveryFunc] = useState(!!(defEveryDay || defEveryDayDayOfWeek));

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        Каждые
        <Box sx={{ width: "152px" }}>
          <TextField
            value={every || ""}
            size="small"
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                const newString = selectedItemRetryStore.includes("EACH_N")
                  ? replaceValue(selectedItemRetryStore, e.target.value, 'EACH_N')
                  : `${selectedItemRetryStore}EACH_N=${e.target.value};`;
                setEvery(e.target.value);
                setSelectedItemRetryStore(newString);
              }
            }}
          />
        </Box>
        год(года)
      </Box>
      <Box sx={{
        display: "flex", gap: "10px", alignItems: "center", paddingTop: "20px"
      }}
      >
        <TableContainer component={Paper}>
          <Table className={style.table} size="small">
            <TableHead>
              <TableRow
                className={style.tableHead}
                sx={{
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                {months.map((dayOfWeek) => (
                  <TableCell
                    key={dayOfWeek.id}
                    sx={{ minWidth: "16.667%" }}
                    className={style.cellTableHead}
                    align="center"
                    style={{ background: selectedDayOfWeek.includes(dayOfWeek.id) ? "#1876d2" : null, cursor: "pointer" }}
                    onClick={() => {
                      setSelectedDayOfWeek(selectedDayOfWeek.includes(dayOfWeek.id)
                        ? (prev) => {
                          const newValue = prev.filter((e) => e !== dayOfWeek.id);
                          const newString = selectedItemRetryStore.includes("MONTH")
                            ? replaceValue(selectedItemRetryStore, newValue.join(), 'MONTH')
                            : `${selectedItemRetryStore}MONTH=${newValue.join()};`;
                          setSelectedItemRetryStore(newString);

                          return newValue;
                        }
                        : (prev) => {
                          const newValue = [...prev, dayOfWeek.id];
                          const newString = selectedItemRetryStore.includes("MONTH")
                            ? replaceValue(selectedItemRetryStore, newValue.join(), 'MONTH')
                            : `${selectedItemRetryStore}MONTH=${newValue.join()};`;
                          setSelectedItemRetryStore(newString);

                          return newValue;
                        });
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: 'text.secondary',
                        fontSize: "16px",
                        fontWeight: "400"
                      }}
                    >
                      {dayOfWeek.value}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody />
          </Table>
        </TableContainer>

      </Box>

      <Box sx={{
        display: "flex", gap: "10px", alignItems: "center", paddingTop: "20px", height: "60px"
      }}
      >
        <Switch
          checked={switchEveryFunc}
          onClick={() => {
            setEveryDayDayOfWeek(null);
            setEveryDay(null);
            setSwitchEveryFunc((prev) => {
              setSelectedItemRetryStore(
                `TYPE=${retryArr[3].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;MONTH=${selectedDayOfWeek.join()}` : ""}${retryCount ? `;${stopRetryArrChoice[0].id}=${retryCount}` : ""}${retryData ? `;${stopRetryArrChoice[1].id}=${new Date(retryData).toISOString()}` : ""};`
              );

              return !prev;
            });
          }}
        />
        {switchEveryFunc ? (
          <>
            <FormControl
              size="small"
              sx={{ minWidth: 120, width: "280px" }}
              error={!everyDay}
            >
              <InputLabel>Каждый</InputLabel>
              <Select
                value={everyDay}
                error={everyDay ? null : "Ошибка"}
                onChange={(event) => {
                  setEveryDay(event.target.value);
                  if (event.target.value) {
                    setSelectedItemRetryStore(
                      `TYPE=${retryArr[3].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;MONTH=${selectedDayOfWeek.join()}` : ""}${event.target.value ? `;ORDER=${event.target.value}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""}${retryCount ? `;${stopRetryArrChoice[0].id}=${retryCount}` : ""}${retryData ? `;${stopRetryArrChoice[1].id}=${new Date(retryData).toISOString()}` : ""};`

                    );
                  }
                }}
                label="Каждый"
              >
                {moreFunctions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {everyDay ? null : (
                <FormHelperText sx={{
                  position: "absolute",
                  top: "35px",
                  left: "-12px"
                }}
                >
                  Обязательное поле для запаолнения
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              size="small"
              sx={{ minWidth: 120, width: "280px" }}
              error={!everyDayDayOfWeek}
            >
              <InputLabel>День недели</InputLabel>
              <Select
                value={everyDayDayOfWeek}
                error={everyDayDayOfWeek ? null : "Ошибка"}
                onChange={(event) => {
                  setEveryDayDayOfWeek(event.target.value);
                  if (event.target.value) {
                    setSelectedItemRetryStore(
                      `TYPE=${retryArr[3].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;MONTH=${selectedDayOfWeek.join()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${event.target.value ? `;DAYS=${event.target.value}` : ""}${retryCount ? `;${stopRetryArrChoice[0].id}=${retryCount}` : ""}${retryData ? `;${stopRetryArrChoice[1].id}=${new Date(retryData).toISOString()}` : ""};`
                    );
                  }
                }}
                label="День недели"
              >
                {daysOfWeek.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
              {everyDayDayOfWeek ? null : (
                <FormHelperText sx={{
                  position: "absolute",
                  top: "35px",
                  left: "-12px"
                }}
                >
                  Обязательное поле для запаолнения
                </FormHelperText>
              )}
            </FormControl>
          </>
        ) : null}

      </Box>

      <Box sx={{
        display: "flex", gap: "10px", alignItems: "center", paddingTop: "20px"
      }}
      >
        <FormControl
          size="small"
          sx={{ minWidth: 120, width: "280px" }}
        >
          <InputLabel>Прекратить повторение</InputLabel>
          <Select
            value={stopRetry}
            onChange={(event) => {
              setStopRetry(event.target.value);
              if (!event.target.value) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[3].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;MONTH=${selectedDayOfWeek.join()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""};`

                );
              }
            }}
            label="Прекратить повторение"
          >
            {stopRetryArrChoice.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {stopRetry === stopRetryArrChoice[1].id ? (
          <DatePicker
            label="Дата"
            value={retryData}
            onChange={(e) => {
              setRetryData(e);
              // eslint-disable-next-line no-restricted-globals
              if (e && !isNaN(e)) {
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[3].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;MONTH=${selectedDayOfWeek.join()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""};${`${stopRetryArrChoice[1].id}=${e.toISOString()};`}`
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
              />
            )}
            components={{
              ActionBar: ActionBarDateTimePicker,
            }}
          />
        ) : stopRetry === stopRetryArrChoice[0].id ? (
          <TextField
            label="Раз"
            size="small"
            value={retryCount || ""}
            onChange={(e) => {
              if (/\d+/.test(Number(e.target.value))) {
                setRetryCount(e.target.value);
                setSelectedItemRetryStore(
                  `TYPE=${retryArr[3].id}${every ? `;EACH_N=${every}` : ""}${selectedDayOfWeek.length ? `;MONTH=${selectedDayOfWeek.join()}` : ""}${everyDay ? `;ORDER=${everyDay}` : ""}${everyDayDayOfWeek ? `;DAYS=${everyDayDayOfWeek}` : ""};${`${stopRetryArrChoice[0].id}=${e.target.value}`};`
                );
              }
            }}
          />
        ) : null }

      </Box>
    </>
  );
}

RetryEveryYear.propTypes = {
  setSelectedItemRetryStore: PropTypes.func.isRequired,
  selectedItemRetryStore: PropTypes.string.isRequired,
};

export default ScheduleWidget;
